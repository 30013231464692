export default {
  masterChef: {
    97:'',
    56:'',
    43114: '', // avax main
    43113: '', // avax test
    137: '', // Polygon
    80001: '', // Polygon test
    86: '', // GateChain main
    85: '', // GateChain test
    321:'', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '', // OEC Mainnet
    65: '', // OEC Testnet
    128:'', // heco main
    256:'', // heco test
    250:'', // Fantom Opera
    4002: '', // Fantom testnet
  },
  multiCall: {
    56: '0xE87BBED21f342262498f28790DDC11c7baB5eFEA',
    97: '0x81DeD473887CcDBb2c0b45CCc214Bcbd1ab67134',
    43114: '0xb14067b3c160e378deeafa8c0d03ff97fbf0c408', // avax main
    43113: '', // avax test
    137: '0xa1B2b503959aedD81512C37e9dce48164ec6a94d', // Polygon
    80001: '', // Polygon test
    86: '0xba6ecf8b8a15a525c3107e81407ea46aca4d97b5', // GateChain main
    85: '', // GateChain test
    321:'0x6a818864f61242243022efebcd91737426a709b9', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '0x6a818864f61242243022efebcd91737426a709b9', // OEC Mainnet
    65: '', // OEC Testnet
    128:'0xe04f7524ae7948403358cf613115347f1b04ae57', // heco main
    256:'', // heco test
    250:'0xb828c456600857abd4ed6c32facc607bd0464f4f', // Fantom Opera
    4002: '', // Fantom testnet
  },
  weth: {
    97:'0x8b1c0aa02b04104b105ada7381c70ca40e141714',
    56:'0x55d398326f99059fF775485246999027B3197955',
    43114: '', // avax main
    43113: '', // avax test
    137: '', // Polygon
    80001: '', // Polygon test
    86: '', // GateChain main
    85: '', // GateChain test
    321:'', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '', // OEC Mainnet
    65: '', // OEC Testnet
    128:'', // heco main
    256:'', // heco test
    250:'', // Fantom Opera
    4002: '', // Fantom testnet
  },
  coinAddress:{
    97:'0x16e8034b55bb825fffc6a049f14930b5d2d830c4', // tfi
    56:'0x7565ab68d3f9dadff127f864103c8c706cf28235', // tfi bsc
    43114: '', // avax main
    43113: '', // avax test
    137: '', // Polygon
    80001: '', // Polygon test
    86: '', // GateChain main
    85: '', // GateChain test
    321:'', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '', // OEC Mainnet
    65: '', // OEC Testnet
    128:'', // heco main
    256:'', // heco test
    250:'', // Fantom Opera
    4002: '', // Fantom testnet
  },
  coinPairAddress:{
    97:'0x2a95d78cf9b5b1a677bfab4dcb5d02a6f7647dff', // tfi-usdt bsc
    56:'0xbe601abc8032f12daa0419de01bd81e4d94980bd', // tfi-usdt
    43114: '', // avax main
    43113: '', // avax test
    137: '', // Polygon
    80001: '', // Polygon test
    86: '', // GateChain main
    85: '', // GateChain test
    321:'', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '', // OEC Mainnet
    65: '', // OEC Testnet
    128:'', // heco main
    256:'', // heco test
    250:'', // Fantom Opera
    4002: '', // Fantom testnet
  },
  stakingFactoryCreator: {
    97: '0x018623E12e16Dd4a2120e4478919B3413EaE8646',
    56: '0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13',
    43114: '0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13', // avax
    43113: '', // avax test
    137: '0xBd770416a3345F91E4B34576cb804a576fa48EB1', // Polygon
    80001: '', // Polygon test
    86: '0x03D83B63ef76695C87024eE08645c673cc3a73fc', // GateChain main
    85: '', // GateChain test
    321:'0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13', // OEC Mainnet
    65: '', // OEC Testnet
    128:'0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13', // heco main
    256:'', // heco test
    250:'0x302e060951232d5DE51dAC9Bc0f9b9aeaf447a13', // Fantom Opera
    4002: '', // Fantom testnet
  },
  bnbToUsdtAddress:{
    97:'0x2a95d78cf9b5b1a677bfab4dcb5d02a6f7647dff', // bnb-usdt
    56:'0x58f876857a02d6762e0101bb5c46a8c1ed44dc16', // bnb-usdt  bsc
    43114: '', // avax main
    43113: '', // avax test
    137: '', // Polygon
    80001: '', // Polygon test
    86: '', // GateChain main
    85: '', // GateChain test
    321:'', // KCC-MAINNET
    322:'', // KCC-TESTNET
    66: '', // OEC Mainnet
    65: '', // OEC Testnet
    128:'', // heco main
    256:'', // heco test
    250:'', // Fantom Opera
    4002: '', // Fantom testnet
  },
}
