import React from 'react'
import moment from 'moment';
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Text, Flex, LinkExternal } from 'trustfi-uikit'
import EditPoolBtn from '../EditPool/EditPoolBtn'
import ClosePoolBtn from '../ClosePool/ClosePoolBtn'
import { Farm } from '../../../../state/types'
import useBlockCountdown from '../../../../hooks/useGetBlockCountdown'
import useCopyClipboard from '../../../../hooks/useCopyClipboard'
import { getExplorerUrl, getShortAddress } from '../../../../utils/formatUtil'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'

export interface ExpandableSectionProps {
  bscScanAddress?: string
  infoAddress?: string
  removed?: boolean
  totalValueFormatted?: string
  lpLabel?: string
  addLiquidityUrl?: string
  endBlock?:number
  mayEndTime?: number
  poolOwner?: string
  farm: Farm
}

const Wrapper = styled.div`
  padding: 24px;
  color: ${({theme})=>theme.expandColor.color.white};
`

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
  display: block;
  & svg {
    vertical-align: sub;
  }
`

const StyledInfoRow = styled.div`
  width: 100%;
`
const StyledInfoItem = styled.div`
  width: 100%;
  text-align: left;
`
const StyledInfoTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top : 5px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
  }
`
const StyledInfoContent = styled.div`
  font-size: 14px;
  margin-top : 5px;
  line-height: 1.4;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
  }
`
const StyledBtn = styled.div`
  position: relative;
  text-align: left;
  margin-top: 5px;
  & a{
    color: ${({theme})=>theme.expandColor.color.active};
    cursor: pointer;
  }
  & span {
    color: ${({theme})=>theme.expandColor.color.active};
  }
`
const StyledBtnRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  & .mr{
    margin-right: 16px;
  }
  & > * {
    flex: 1;
  }
  & ${StyledBtn}{
    & > * {
      margin-right: 0;
    }
  }
`

const DetailsSection: React.FC<ExpandableSectionProps> = ({
  bscScanAddress,
  infoAddress,
  removed,
  totalValueFormatted,
  lpLabel,
  addLiquidityUrl,
  endBlock,
  mayEndTime,
  poolOwner,
  farm
}) => {
  const { t } = useTranslation()
  const { account,chainId } = useActiveWeb3React()
  const lockDay = farm.lockSeconds / 24/60/60
  const explorerUrl = getExplorerUrl(chainId)

  const secondsRemaining = useBlockCountdown(farm.startBlock)
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <Wrapper>
      <StyledInfoRow>
        <StyledInfoItem>
          <StyledInfoTitle>Description</StyledInfoTitle>
          <StyledInfoContent>Each coin you add to the stake is automatically harvested</StyledInfoContent>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledInfoTitle>Unstaking Fee</StyledInfoTitle>
          <StyledInfoContent>Unstake within {lockDay} days will be charged {farm.feeValue}% fees. Timer resets every time you Unstake and stake</StyledInfoContent>
        </StyledInfoItem>
      </StyledInfoRow>
      {!removed && (
        <StyledLinkExternal href={addLiquidityUrl}>{t(`Get ${lpLabel}`, { name: lpLabel })}</StyledLinkExternal>
      )}
      <StyledLinkExternal href={`${explorerUrl}/address/${farm.coreAddress}`}>{t('Contract')}</StyledLinkExternal>
      <StyledLinkExternal href={`${explorerUrl}/address/${farm.stakeTokenAddress}`}>{t('Staking Token/Lp Contract')}</StyledLinkExternal>
      <StyledLinkExternal href={`${explorerUrl}/address/${farm.rewardToken}`}>{t('Reward Token Contract')}</StyledLinkExternal>
      <StyledBtn>
        {t('View Project Site: ')}
        {farm.poolViewInfo && farm.poolViewInfo.officialSite && (
          <a href={`${farm.poolViewInfo.officialSite.slice(0,4) === "http"?'':'https://'}${farm.poolViewInfo.officialSite}`} target="_blank" rel="noreferrer">
            {farm.poolViewInfo.officialSite}
          </a>
        )}

      </StyledBtn>
      <StyledBtn>
        {t('Pool Max Stake Amount: ')}
        <span>
          {farm.maxStakeAmount>0 ? `${farm.maxStakeAmount} ${farm.stakeTokenSymbol}`: 'Unrestricted'}

        </span>
      </StyledBtn>
      <StyledBtn>
        {t('Rewards end in: ')}
        <span>
          {endBlock?moment(endBlock*1000).format("YYYY-MM-DD HH:mm:ss"):moment(mayEndTime).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </StyledBtn>
      {account && poolOwner.toLowerCase()===account.toLowerCase() &&farm.endBlock===0&& secondsRemaining>0 &&(
        <StyledBtnRow>
          <StyledBtn className="mr">
            <EditPoolBtn farm={farm}/>
          </StyledBtn>
          <StyledBtn>
            <ClosePoolBtn farm={farm}/>
          </StyledBtn>
        </StyledBtnRow>
      )}
    </Wrapper>
  )
}

export default DetailsSection
