import styled from 'styled-components'

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  box-sizing: border-box;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
`
export const SectionContainer = styled.div`
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 15px;
  }
`

export default Container
