import React, { useState, useMemo } from 'react'
import { Input } from 'trustfi-uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { Search } from '@material-ui/icons';

const StyledInput = styled(Input)`
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  margin-left: auto;
  background-color: ${({theme})=>theme.expandColor.color.pageBg};
  height: 38px; 
  color: ${({theme})=>theme.expandColor.color.active};
  padding-left: 50px;
  position: relative;
  &::placeholder {
    color: ${({theme})=>theme.expandColor.color.color3};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: none;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 50px;
  }
 
`

const InputWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 300px;
    display: block;
  }
   
  & svg {
    fill:  ${({theme})=>theme.expandColor.color.white};
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0px;
    margin: auto;
  }
`

const Container = styled.div`
  padding: 30px 0;
`

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: React.FC<Props> = ({ onChange: onChangeCallback }) => {
  const [searchText, setSearchText] = useState('')

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <Container>
      <InputWrapper>
        <StyledInput
          value={searchText}
          onChange={onChange}
          placeholder="Search Spaces"
        />
        <Search style={{ fontSize: 30 }}/>
      </InputWrapper>
    </Container>
  )
}

export default SearchInput
