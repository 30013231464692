import React from 'react'
import styled from 'styled-components'

const TipRow:React.FC = ()=>{
  return (
    <StyledRow>
      ❗️To stake $TFI for IDO participation,
      please <a href="https://farmerbsc.trustfi.org/space/idotiers" rel="noreferrer" target="_blank">click here</a>.
      To learn how our Tier System and IDO Tier Pools work, please
      read <a href="https://medium.com/trustfi/trustfi-launchpads-tier-system-75bc2ab1cc60" rel="noreferrer" target="_blank">Launchpad Tier System </a>
      and <a href="https://medium.com/trustfi/introducing-trustfi-launchpads-new-tier-pools-ba896de6f933">New Tier Pools</a> articles.
    </StyledRow>
  )
}

const StyledRow = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  padding: 15px;
  font-size: 16px;
  color:  ${({theme})=>theme.expandColor.color.white};
  line-height: 1.5;
  & a {
    color: ${({theme})=>theme.expandColor.color.active};
  }
`

export default TipRow