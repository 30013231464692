export type TableProps = {
  data?: TableDataTypes[]
  selectedFilters?: string
  sortBy?: string
  sortDir?: string
  onSort?: (value: string) => void
}

export type ColumnsDefTypes = {
  id: number
  label: string
  name: string
  translationId: number
  sortable: boolean
  divW?: string
}

export type ScrollBarProps = {
  ref: string
  width: number
}

export type TableDataTypes = {
  POOL: string
  APR: string
  EARNED: string
  STAKED: string
  DETAILS: string
  LINKS: string
}

export const MobileColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'farm',
    translationId: 999,
    sortable: true,
    label: '',
  },
  {
    id: 2,
    name: 'earned',
    translationId: 1072,
    sortable: true,
    label: 'Earned',
  },
  {
    id: 3,
    name: 'apr',
    translationId: 736,
    sortable: true,
    label: 'APR',
  },
  {
    id: 6,
    name: 'details',
    translationId: 999,
    sortable: true,
    label: '',
  },
]

export const DesktopColumnSchema: ColumnsDefTypes[] = [
  {
    id: 1,
    name: 'farm',
    translationId: 999,
    sortable: true,
    label: '',
    divW: '30%',
  },
  {
    id: 2,
    name: 'apr',
    translationId: 736,
    sortable: true,
    label: 'APR',
    divW: '13%',
  },
  {
    id: 6,
    name: 'earned',
    translationId: 1072,
    sortable: true,
    label: 'EARNED',
    divW: '11%',
  },
  {
    id: 8,
    name: 'staked',
    translationId: 1072,
    sortable: true,
    label: 'STAKED',
    divW: '11%',
  },
  {
    id: 7,
    name: 'actionbtn',
    translationId: 999,
    sortable: true,
    label: '',
    divW: '30%',
  },
  {
    id: 9,
    name: 'details',
    translationId: 999,
    sortable: true,
    label: '',
    divW: '5%',
  },
]

export enum ViewMode {
  'TABLE' = 'TABLE',
  'CARD' = 'CARD',
}

export const CreateOrEditPoolTips = {
  poolName: 'Your pool display name (e.g. TrustFi Governance Pool)',
  poolType: 'Do you require pool participants to stake single asset tokens or Liquidity Provider tokens?',
  stakeTokenLpAddress: 'The contract address for the token/LP you want the pool participants to stake (e.g. TFI token address: 0x7565ab68d3f9dadff127f864103c8c706cf28235. \nTFI-BNB LP address: 0xb1c508f942c1d1b1a44384bcc92885e43887d0fa)',
  stakeTokenLpLogoUrl: 'PNG, JPG, Max 1Mb, Recommend 1:1 Portrait.',
  stakingTokenPairAddress: 'Staking Token Pair Address will be used to retrieve price data and to calculate APR. Be sure to have matching LP pairs for Staking and Reward (e.g. TFI/USDT for staking matches with CHEERS/USDT for rewards, which both use USDT).',
  rewardTokenAddress:'Enter the contract address for your pool’s designated reward token (e.g. If you wanna create the pool about staking TFI to earn CHEERS, you need to enter CHEERS token address: 0xbBBcB350C64Fe974e5C42A55c7070644191823f3).',
  rewardTokenLogoUrl: 'PNG, JPG, Max 1Mb, Recommend 1:1 Portrait.',
  rewardTokenPairAddress:  'Reward Token Pair Address will be used to retrieve price data and to calculate APR. Be sure to have matching LP pairs for Staking and Reward (e.g. TFI/USDT for staking matches with CHEERS/USDT for rewards, , which both use USDT).',
  stakingUpperLimitPerUser: 'Specify the maximum staking amount required for each pool participant. No enter if you don’t need it.',
  stakingLowerLimitPerUser: 'Specify the minimum staking amount required for each pool participant. No enter if you don’t need it.',
  poolHardCap: 'Enter the hard cap for the amount of staked token/LP for your pool (e.g. If you wanna create the pool about staking TFI with hard cap of 10k TFI, you need to enter 10000 here). No enter if you don’t need it.',
  poolStartingTime: 'Specify the exact date and time your pool will start at. Users can only stake after the starting time.',
  poolEndingTime: 'Specify the exact date and time your pool will end at. Users can only stake before end time, but can unstake at any time.',
  lockedDuration: 'Specify the amount of days you want the pool participants to be locked in after staking. \n While the pool participants unstake token/LP within Locked Duration, they’ll be charged unstaking fees, which will be automatically transferred to the address you set. There’s no unstaking fee if the user unstaked token/LP after the locked duration (e.g. If someone staked 1000 TFI in the pool you created which you set 7 days as the locked duration and 10% as the unstaking fees, you will receive 100 TFI in the address you set while the user unstaked his 1000 TFI. You need to enter 7 here to make the example happen).',
  unstakingFeesInLockedDuration: 'Enter the percentage penalty for unstaking during the Locked Duration (e.g. If someone staked 1000 TFI in the pool you created which you set 7 days as the locked duration and 10% as the unstaking fees, you will receive 100 TFI in the address you set while the user unstaked his 1000 TFI. You need to enter 10 here to make the example happen).',
  addressToReceiveUnstakingFees: 'Incurred unstaking fees will be automatically transferred to this address (e.g. If someone staked 1000 TFI in the pool you created which you set 7 days as the locked duration and 10% as the unstaking fees, you will receive 100 TFI in this address while the user unstaked his 1000 TFI. Enter your address here). The unstaking fees will be transferred to the creator address if you entered nothing.',
  totalRewards: 'The total amount of reward tokens reserved for your pool.',
  officialWebsite: 'Link your official project website.',
  twitter: 'Link your Project’s Twitter account.',
  telegram: 'Link your project’s Telegram Announcement Channel or Community Chat',
  paymentMethod: 'You can select which payment token you want to use for creating this pool.',
}
export const CreateInputDefault = {
  stakedTokenAddress: '',
  stakedTokenName: '',
  poolTypeV: 0,
  userMaxStakeAmount: '',
  userMinStakeAmount: '',
  maxStakeAmount: '',
  lockDays: '',
  feeValue: '',
  rewardTotals: '',
  rewardsTokenAddress: '',
  startTime: new Date().getTime(),
  endTime: new Date().getTime(),
  commissionTokenAddress: '',
  officialSite: '',
  twitterLink: '',
  telegramLink: '',
  feeAddress: '',
  stakeTokenUsdt: '',
  rewardTokenUsdt: '',
  stakeLogo: '',
  rewardLogo: '',
}
