import styled from 'styled-components'
import { Button,IconButton } from 'trustfi-uikit'
import Container from '../../components/layout/Container'

const ContainerType = styled.div`
    width: 100%;
    margin: auto;
    position: relative;
    box-sizing: border-box;
`
export const UiContainerType1 = styled(ContainerType)`
  display: flex;
  
  &.flex-between {
    justify-content: space-between;
    align-items: center;
  }
  &.flex-center {
    justify-content: center;
  }
  &.padding-30 {
    padding: 30px;
  }
`

export const UiContainerType2 = styled(ContainerType)`
  max-width: 1200px;
  padding: 0 15px;
`

export const UiLuminousTitle = styled.div`
  color: ${({theme})=>theme.expandColor.color.active};
  font-size: 24px;
  text-shadow: 0 0 6px ${({theme})=>theme.expandColor.color.active};
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 36px;
  } 
`

export const UiStyledButton = styled(Button)`
  padding: 0 15px;
  height: 32px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 24px;
    height: 48px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 24px;
    height: 48px;
  }
  width: 100%;
`
export const UiStyledIconButton = styled(IconButton)`
  padding: 0 15px;
  height: 36px;
  width: 32px;
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 24px;
    height: 48px;
    width: 32px;
  }
`

export const UiLuminousBtn = styled.div`
  color: ${({theme})=>theme.expandColor.color.active};
  height: 32px;
  padding: 0 15px;
  border-radius:  ${({theme})=>theme.expandColor.radius.radius1};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  text-shadow: 0 0 6px ${({theme})=>theme.expandColor.color.active};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 38px;
    padding: 0 20px;
    font-size: 14px;
  } 
  
  &.flex-1 {
      flex: 1;
  }
  &.mr-16 {
    margin-right: 16px;
  } 
  &.mr-10 {
    margin-right: 10px;
  } 
  &.active {
    color: ${({theme})=>theme.expandColor.color.black};
    background: ${({theme})=>theme.expandColor.color.active};
  }
`
export const UiModalBtn = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  flex-direction: column;
  cursor: pointer;
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  &:hover {
    opacity: 0.8;
  }
  & h3{
    font-size: 20px;
  }
  & h4{
    font-size: 14px;
  }
  &.disable {
    cursor: not-allowed;
    background-color:${({theme})=>theme.expandColor.color.disable};
    color: ${({theme})=>theme.expandColor.color.color3};
    border: 2px solid ${({theme})=>theme.expandColor.color.disable};
  }
  &.mr{
    margin-right: 0px;
    ${({ theme }) => theme.mediaQueries.sm} {
      margin-right: 15px;
    } 
  }
  &.mb{
    margin-bottom: 15px;
    ${({ theme }) => theme.mediaQueries.sm} {
      margin-bottom: 0px;
    } 
  }
  
  &.back-color {
    background-color:${({theme})=>theme.expandColor.color.black};
    color: ${({theme})=>theme.expandColor.color.active};
  }
  &.width-auto {
    width: auto;
    padding: 0 20px;
  }
`
