import React, { useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, IconButton, AddIcon, MinusIcon, useModal, Text } from 'trustfi-uikit'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'
import useStake from '../../hooks/useStake'
import useUnstake from '../../hooks/useUnstake'
import { Farm } from '../../../../state/types'

interface FarmCardActionsProps {
  stakedBalance?: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid?: number
  addLiquidityUrl?: string
  stakeSymbol?: string
  factoryAddress?: string
  farm: Farm
  stakeTokenDecimals: number
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
                                                       stakedBalance,
                                                       tokenBalance,
                                                       tokenName,
                                                       pid,
                                                       addLiquidityUrl,
                                                       stakeSymbol,
                                                       farm,
                                                       factoryAddress,
                                                       stakeTokenDecimals,
                                                     }) => {
  const { t } = useTranslation()
  const { onStake } = useStake(factoryAddress,pid)
  const { onUnstake } = useUnstake(factoryAddress,pid)
  const location = useLocation()

  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance,stakeTokenDecimals)
    if (stakedBalanceNumber > 0 && stakedBalanceNumber < 0.0001) {
      return getFullDisplayBalance(stakedBalance,stakeTokenDecimals).toLocaleString()
    }
    return stakedBalanceNumber.toLocaleString()
  }, [stakedBalance,stakeTokenDecimals])

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={tokenName} addLiquidityUrl={addLiquidityUrl} farm={farm}/>,
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={tokenName}  stakeTokenDecimals={farm.stakeTokenDecimals} farm={farm}/>,
  )

  const renderStakingButtons = () => {
    return stakedBalance.eq(0) ? (
      <StyledBtn
        onClick={onPresentDeposit}
        disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
      >
        {t('Stake')}
      </StyledBtn>
    ) : (
      <IconButtonWrapper>
        <IconButton variant="secondary" onClick={onPresentWithdraw} mr="6px">
          <MinusIcon color="primary" width="14px" />
        </IconButton>
        <IconButton
          variant="secondary"
          onClick={onPresentDeposit}
          disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
        >
          <AddIcon color="primary" width="14px" />
        </IconButton>
      </IconButtonWrapper>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Text bold color="white" fontSize="16px" pr="3px" textAlign="left" lineHeight={1}>
          {stakeSymbol} {t('Staked')}
        </Text>
        <Heading textAlign="left" color='primary'>{displayBalance()}</Heading>
      </Flex>
      {renderStakingButtons()}
    </Flex>
  )
}

const StyledBtn = styled(Button)`
  width: 110px;
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  color: ${({theme})=>theme.expandColor.color.black};
`
export default StakeAction
