import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR, CAKE_PER_BLOCK, DEFAULT_TOKEN_DECIMAL } from 'config'
import {BIG_ZERO} from "./bigNumber";

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get the APR value in %
 * rewardPerBlock * BLOCKS_PER_YEAR * rewardTokenPrice / totalWethValue
 * @param rewardTokenPrice Token price in the same quote currency
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolAprStakeLP = (
  rewardTokenPrice: BigNumber,
  tokenPerBlock: BigNumber,
  poolLiquidityUsd: BigNumber,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const apr = poolLiquidityUsd.toNumber()?totalRewardPricePerYear.div(poolLiquidityUsd).times(100):BIG_ZERO
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 *   rewardPerBlock * BLOCKS_PER_YEAR * poolWeight / totalWethValue
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param earnTokenPrice in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (poolWeight: BigNumber, earnTokenPrice: BigNumber, poolLiquidityUsd: BigNumber, perBlock: BigNumber): number => {
  const yearlyCakeRewardAllocation = perBlock.times(BLOCKS_PER_YEAR).times(poolWeight)
  const apr = poolLiquidityUsd.toNumber()?yearlyCakeRewardAllocation.times(earnTokenPrice).div(poolLiquidityUsd).times(100):BIG_ZERO
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

// lp apr
export const getFarmAprLP = ( rewardPrice: BigNumber, poolLiquidityUsd: BigNumber, perBlock: BigNumber): number => {
  const yearlyCakeRewardAllocation = perBlock.times(BLOCKS_PER_YEAR)
  const apr = poolLiquidityUsd.toNumber()?yearlyCakeRewardAllocation.times(rewardPrice).div(poolLiquidityUsd).times(100):BIG_ZERO
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

// token apr
export const getFarmAprToken = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(new BigNumber(totalStaked))
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export const getApy = ({
   numberOfDays,
   farmApr,
   tokenPrice,
   roundingDecimals = 2,
   compoundFrequency = 1,
   performanceFee = 0,
 }) => {
  const aprAsDecimal = farmApr / 100
  const daysAsDecimalOfYear = numberOfDays / 365
  const principal = 1000 / tokenPrice
  return parseFloat((aprAsDecimal* daysAsDecimalOfYear* principal).toFixed(roundingDecimals))
}

export default null
