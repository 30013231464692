import React from 'react'
import NotFound from '../NotFound'
import Page from '../../components/layout/Page'
import { UiContainerType1 } from './UiLayout'
import Circular from './Circular'

interface props {
  loaded: boolean
}
const PageLoading:React.FC<props> = ({loaded})=>{

  return loaded?(
    <NotFound/>
  ):(
    <Page>
      <UiContainerType1 className="flex-center  padding-30">
        <Circular/>
      </UiContainerType1>
    </Page>
  )
}

export default PageLoading
