import styled from 'styled-components'

const FlexLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -15px;
  & > * {
    min-width: 280px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin-bottom: 32px;
    ${({ theme }) => theme.mediaQueries.sm} {
       max-width: 33.333%;
    }
  }
`

export default FlexLayout
