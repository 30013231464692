import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import Collapse from "@material-ui/core/Collapse";
import { useSnackbar } from 'notistack'

import InfoInput from './InfoInput'
import ModalTheme from './ModalTheme'
import InputLabel, { InputLabelNoTip } from './InputLabel'
import TimerPicker from './TimePicker'
import useCreatePool from '../../hooks/useCreatePool'
import PaymentSelect, { PaymentSelectOptionProps } from './PaymentSelect'
import { useFarms } from '../../../../state/farms/hooks'
import PoolType from './PoolType'
import { useApproveTokenToCreate } from '../../hooks/useApprove'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../../../../state/farms'
import { useAppDispatch } from '../../../../state'
import { CreateInputDefault, CreateOrEditPoolTips, ViewMode } from '../types'
import { ChainNameLogo } from '../../../../components/layout/ChainLogo'
import useGetUserCommTokens from '../../hooks/useGetUserCommTokens'
import usePersistState from '../../../../hooks/usePersistState'
import { UiModalBtn } from '../../../components/UiLayout'
import UploadImg from './UploadImg'

interface ModalProps {
  onDismiss?: () => void
}

const CreateModal: React.FC<ModalProps> =
  ({
     onDismiss,
   }) => {
    const { t } = useTranslation()
    const {account} = useWeb3React()

    // Get browser cached input data
    const [localData, setLocalData] = usePersistState(CreateInputDefault, `tfi-create-user-${account}`)

    const [stakedToken,setStakedToken] = useState(localData?localData.stakedTokenAddress:'')
    const handleSetStakedToken = (event: React.ChangeEvent<HTMLInputElement>) => {
      setStakedToken(event.target.value)
    }

    const [poolType,setPoolType] = useState(localData?localData.poolTypeV:0)
    const handleSetPoolType = (event: number) => {
      setPoolType(event)
    }

    // poolname
    const [lpOrFormat,setLpOrFormat] = useState(localData?localData.stakedTokenName:'')
    const handleSetLpOrFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLpOrFormat(event.target.value)
    }
    // upload stake logo
    const [logoUrl,setLogoUrl] = useState(localData?localData.stakeLogo:'')
    const handleSetLogoUrl = (event: string) => {
      setLogoUrl(event)
    }
    const [stakeTokenUsdt,setStakeTokenUsdt] = useState(localData?localData.stakeTokenUsdt:'')
    const handleSetStakeTokenUsdt = (event: React.ChangeEvent<HTMLInputElement>) => {
      setStakeTokenUsdt(event.target.value)
    }
    const [maxStakingAmountUser,setMaxStakingAmountUser] = useState(localData?localData.userMaxStakeAmount:'')
    const handleSetMaxStakingAmountUser = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMaxStakingAmountUser(event.target.value)
    }
    const [minStakingAmountUser,setMinStakingAmountUser] = useState(localData?localData.userMinStakeAmount:'')
    const handleSetMinStakingAmountUser = (event: React.ChangeEvent<HTMLInputElement>) => {
      setMinStakingAmountUser(event.target.value)
    }
    const [poolHardCap,setPoolHardCap] = useState(localData?localData.maxStakeAmount:'')
    const handleSetPoolHardCap = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPoolHardCap(event.target.value)
    }
    const [chargeDaysOfStake,setChargeDaysOfStake] = useState(localData?localData.lockDays:'')
    const handleSetChargeDaysOfStake = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChargeDaysOfStake(event.target.value)
    }
    const [unstakingFees,setUnstakingFees] = useState(localData?localData.feeValue:'')
    const handleSetUnstakingFees = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnstakingFees(event.target.value)
    }
    const [totalRewards,setTotalRewards] = useState(localData?localData.rewardTotals:'')
    const handleSetTotalRewards = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTotalRewards(event.target.value)
    }
    const [rewardsContract,setRewardsContract] = useState(localData?localData.rewardsTokenAddress:'')
    const handleSetRewardsContract = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRewardsContract(event.target.value)
    }
    // upload reward logo
    const [rewardsLogoUrl,setRewardsLogoUrl] = useState(localData?localData.rewardLogo:'')
    const handleSetRewardsLogoUrl = (event: string) => {
      setRewardsLogoUrl(event)
    }
    const [rewardTokenUsdt,setRewardTokenUsdt] = useState(localData?localData.rewardTokenUsdt:'')
    const handleSetRewardTokenUsdt = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRewardTokenUsdt(event.target.value)
    }
    const [poolStarts,setPoolStarts] = useState<Date>(localData?new Date(localData.startTime):null)
    const handleSetPoolStarts = (event: Date) => {
      setPoolStarts(event)
    }
    const [distributionDuration,setDistributionDuration] = useState<Date>(localData?new Date(localData.endTime):null)
    const handleSetDistributionDuration = (event: Date) => {
      setDistributionDuration(event)
    }
    const [officialWebsite,setOfficialWebsite] = useState(localData?localData.officialSite:'')
    const handleSetOfficialWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
      setOfficialWebsite(event.target.value)
    }
    const [twitter,setTwitter] = useState(localData?localData.twitterLink:'')
    const handleSetTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTwitter(event.target.value)
    }
    const [telegram,setTelegram] = useState(localData?localData.telegramLink:'')
    const handleSetTelegram = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTelegram(event.target.value)
    }
    const [paymentOption,setPaymentOption] = useState(localData?localData.commissionTokenAddress:'')
    const [paymentSymbol,setPaymentSymbol] = useState('')
    const [paymentAmount,setPaymentAmount] = useState('')
    const [userCanCreate,setUserCanCreate] = useState(false)

    const handleSetPaymentOption = (option: PaymentSelectOptionProps) => {
      setPaymentOption(option.address)
      setPaymentSymbol(option.symbol)
      setPaymentAmount(option.poolAmount)
      if(new BigNumber(option.poolAmount).lte(new BigNumber(option.userBalance))){
        setUserCanCreate(true)
      }else{
        setUserCanCreate(false)
      }
    }
    const [receiveFeeAddress,setReceiveFeeAddress] = useState(localData?localData.feeAddress:'')
    const handleSetReceiveFeeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
      setReceiveFeeAddress(event.target.value)
    }

    const paymentOptionArr:PaymentSelectOptionProps[] = useGetUserCommTokens()
    useEffect(()=>{
      if(paymentOptionArr.length>0){
        setPaymentOption(paymentOptionArr[0].address)
        setPaymentSymbol(paymentOptionArr[0].symbol)
        setPaymentAmount(paymentOptionArr[0].poolAmount)
        if(new BigNumber(paymentOptionArr[0].userBalance).isGreaterThan(0)&&new BigNumber(paymentOptionArr[0].poolAmount).lte(new BigNumber(paymentOptionArr[0].userBalance))){
          setUserCanCreate(true)
        }else{
          setUserCanCreate(false)
        }
      }
      if(localData&&localData.commissionTokenAddress){
        const findPaymentOption = paymentOptionArr.find((item)=>item.address.toLowerCase()===localData.commissionTokenAddress.toLowerCase())
        if(findPaymentOption){
          setPaymentOption(findPaymentOption.address)
          setPaymentSymbol(findPaymentOption.symbol)
          setPaymentAmount(findPaymentOption.poolAmount)
          if(new BigNumber(findPaymentOption.poolAmount).lte(new BigNumber(findPaymentOption.userBalance))){
            setUserCanCreate(true)
          }else{
            setUserCanCreate(false)
          }
        }else{
          setPaymentOption(paymentOptionArr[0].address)
          setPaymentSymbol(paymentOptionArr[0].symbol)
          setPaymentAmount(paymentOptionArr[0].poolAmount)
          if(new BigNumber(paymentOptionArr[0].userBalance).isGreaterThan(0)&&new BigNumber(paymentOptionArr[0].poolAmount).lte(new BigNumber(paymentOptionArr[0].userBalance))){
            setUserCanCreate(true)
          }else{
            setUserCanCreate(false)
          }
        }

      }


    },[paymentOptionArr,setPaymentOption,setPaymentSymbol,setPaymentAmount,setUserCanCreate,localData])

    const [pendingTx, setPendingTx] = useState(false)
    const {onApproveTokenToFactory,onAllowanceToken} = useApproveTokenToCreate()
    const [rewardsTokenAllowance,setRewardsTokenAllowance] = useState(false)
    const [paymentTokenAllowance,setPaymentTokenAllowance] = useState(false)
    useEffect(()=>{
      onAllowanceToken(rewardsContract).then((res)=>{
        setRewardsTokenAllowance(res)
      })
      onAllowanceToken(paymentOption).then((res)=>{
        setPaymentTokenAllowance(res)
      })
    },[
      setRewardsTokenAllowance,onAllowanceToken,
      rewardsContract,pendingTx,
      setPaymentTokenAllowance,paymentOption,
    ])
    // create pool
    const {onCreatePool} = useCreatePool()
    const { enqueueSnackbar } = useSnackbar();
    const createPoolErrorText = t('Please complete the information')
    const dispatch = useAppDispatch()
    const {data:poolList} = useFarms()
    const handleCreateFun = async()=>{
      if(pendingTx||rewardsContract===''||paymentOption==='') return
      const inputInfo = {
        stakedTokenAddress: stakedToken,
        stakedTokenName: lpOrFormat,
        poolTypeV: poolType,
        userMaxStakeAmount: maxStakingAmountUser===''?'0':maxStakingAmountUser,
        userMinStakeAmount: minStakingAmountUser===''?'0':minStakingAmountUser,
        maxStakeAmount: poolHardCap===''?'0':poolHardCap,
        lockDays: chargeDaysOfStake===''?'0':chargeDaysOfStake,
        feeValue: unstakingFees===''?'0':unstakingFees,
        rewardTotals: totalRewards,
        rewardsTokenAddress: rewardsContract,
        startTime:poolStarts.getTime(),
        endTime: distributionDuration.getTime(),
        commissionTokenAddress: paymentOption,
        officialSite: officialWebsite,
        twitterLink: twitter,
        telegramLink: telegram,
        feeAddress: receiveFeeAddress===''?account:receiveFeeAddress,
        stakeTokenUsdt: stakeTokenUsdt.toString(),
        rewardTokenUsdt: rewardTokenUsdt.toString(),
        stakeLogo: logoUrl,
        rewardLogo: rewardsLogoUrl,
      }
      setLocalData(inputInfo)

      // check approve reward token
      if(!rewardsTokenAllowance){
        setPendingTx(true)
        const resAR = await onApproveTokenToFactory(rewardsContract)
        if(resAR){
          enqueueSnackbar('Approve Reward Token Successfully', {
            variant:'success' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
        }else{
          enqueueSnackbar('Please try again. Confirm the transaction and make sure you are paying enough gas!', {
            variant:'error' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
        }
        setPendingTx(false)
        return
      }
      // check approve payment token
      if(!paymentTokenAllowance){
        setPendingTx(true)
        const resAP = await onApproveTokenToFactory(paymentOption)
        if(resAP){
          enqueueSnackbar('Approve Payment Token Successfully', {
            variant:'success' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
        }else{
          enqueueSnackbar('Please try again. Confirm the transaction and make sure you are paying enough gas!', {
            variant:'error' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
        }
        setPendingTx(false)
        return
      }
      // check input
      if(lpOrFormat===''||stakedToken===''||logoUrl===''||stakeTokenUsdt===''
        ||rewardsContract===''||rewardsLogoUrl===''||rewardTokenUsdt===''
        ||!poolStarts||!distributionDuration||totalRewards===''
        ||paymentOption===''
      ) {
        enqueueSnackbar(createPoolErrorText, {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }
      // check poolname length
      if(lpOrFormat.length>40){
        enqueueSnackbar('Please short Your Pool Name', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }
      // check user userMaxStakeAmount/ userMinStakeAmount
      if(inputInfo.userMaxStakeAmount!== '0' && (Number(inputInfo.userMinStakeAmount)>Number(inputInfo.userMaxStakeAmount))){
        enqueueSnackbar('Staking Lower Limit per User is big than Upper ', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }
      // if user min/max stake has set, the maxStakeAmount must  set
      // maxStakeAmount === 0 &&  userMinStakeAmount/userMaxStakeAmount !==0
      if(inputInfo.userMinStakeAmount!=='0'||inputInfo.userMaxStakeAmount!=='0'){
        if(inputInfo.maxStakeAmount==='0'){
          enqueueSnackbar('Pool Hard Cap must set, and it need big than Staking Lower or Upper Limit per User', {
            variant:'error' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
          return
        }
        if(inputInfo.maxStakeAmount!=='0' && (Number(inputInfo.userMinStakeAmount)>Number(inputInfo.maxStakeAmount)||Number(inputInfo.userMaxStakeAmount)>Number(inputInfo.maxStakeAmount))){
          enqueueSnackbar('Pool Hard Cap must big than Staking Lower or Upper Limit per User', {
            variant:'error' ,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            autoHideDuration: 2500,
            TransitionComponent: Collapse,
          });
          return
        }
      }

      if(!userCanCreate){
        enqueueSnackbar(`Insufficient ${paymentSymbol} Balance`, {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }

      // create pool
      setPendingTx(true)
      const res = await onCreatePool(inputInfo)
      if(res){
        enqueueSnackbar('Create Pool Successfully', {
          variant:'success' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        dispatch(fetchFarmsPublicDataAsync())
        dispatch(fetchFarmUserDataAsync({account:account.toString(),farms:poolList}))
        onDismiss()
      }
      setPendingTx(false)
    }

    return (
      <ModalTheme title="Create Pool" onDismiss={onDismiss}>
        <ModalBox>
          <StyledInputRow>
            <InputLabelNoTip label={t('Chain')}/>
            <div style={{flex: 1,color: '#fff'}}>
              <ChainNameLogo/>
            </div>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Pool Name')}
              tooltipId="lp-format"
              tipsText={CreateOrEditPoolTips.poolName}
            />
            <InfoInput value={lpOrFormat}  onChange={handleSetLpOrFormat} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Pool Type')}
              tooltipId="pool-type"
              tipsText={CreateOrEditPoolTips.poolType}
            />
            <PoolType onChange={handleSetPoolType} poolType={poolType}/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Official Website')}
              tooltipId="official-website"
              tipsText={CreateOrEditPoolTips.officialWebsite}
            />
            <InfoInput  value={officialWebsite} onChange={handleSetOfficialWebsite} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Staking Token/LP Address:')}
              tooltipId="staked-token"
              tipsText={CreateOrEditPoolTips.stakeTokenLpAddress}
            />
            <InfoInput value={stakedToken}  onChange={handleSetStakedToken} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Reward Token Address')}
              tooltipId="rewards-contract"
              tipsText={CreateOrEditPoolTips.rewardTokenAddress}
            />
            <InfoInput  value={rewardsContract} onChange={handleSetRewardsContract} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Staking Token/LP LOGO')}
              tooltipId="logo-url"
              tipsText={CreateOrEditPoolTips.stakeTokenLpLogoUrl}
            />
            {/*
          <InfoInput  value={logoUrl} onChange={handleSetLogoUrl} placeholder=""/>
          */}
            <UploadImg inputId="staking-logo-input" value={logoUrl} onChange={handleSetLogoUrl}/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Reward Token LOGO')}
              tooltipId="rewards-logo-url"
              tipsText={CreateOrEditPoolTips.rewardTokenLogoUrl}
            />
            {/*
          <InfoInput  value={rewardsLogoUrl} onChange={handleSetRewardsLogoUrl} placeholder=""/>
          */}
            <UploadImg inputId="reward-logo-input" value={rewardsLogoUrl} onChange={handleSetRewardsLogoUrl}/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Staking Token Pair Address')}
              tooltipId="stake-token-usdt-pair"
              tipsText={CreateOrEditPoolTips.stakingTokenPairAddress}
            />
            <InfoInput  value={stakeTokenUsdt} onChange={handleSetStakeTokenUsdt} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Reward Token Pair Address')}
              tooltipId="reward-token-usdt-pair"
              tipsText={CreateOrEditPoolTips.rewardTokenPairAddress}
            />
            <InfoInput  value={rewardTokenUsdt} onChange={handleSetRewardTokenUsdt} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Total Rewards')}
              tooltipId="total-rewards"
              tipsText={CreateOrEditPoolTips.totalRewards}
            />
            <InfoInput  value={totalRewards} onChange={handleSetTotalRewards} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Pool Starting Time')}
              tooltipId="pool-starts"
              tipsText={CreateOrEditPoolTips.poolStartingTime}
            />
            <TimerPicker  value={poolStarts}  onChange={handleSetPoolStarts}/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Pool Ending Time')}
              tooltipId="distribution-duration"
              tipsText={CreateOrEditPoolTips.poolEndingTime}
            />
            <TimerPicker  value={distributionDuration} onChange={handleSetDistributionDuration}/>
          </StyledInputRow>
          {/* stake locked days  */}
          <StyledInputRow>
            <InputLabel
              label={t('Set Duration(day) for unstaking fees (Optional)')}
              tooltipId="charge-days-staking"
              tipsText={CreateOrEditPoolTips.lockedDuration}
            />
            <InfoInput  value={chargeDaysOfStake} onChange={handleSetChargeDaysOfStake} placeholder="0"/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Set Unstaking Fees (%) in the Duration (Optional)')}
              tooltipId="unstaking-fees"
              tipsText={CreateOrEditPoolTips.unstakingFeesInLockedDuration}
            />
            <InfoInput  value={unstakingFees} onChange={handleSetUnstakingFees} placeholder="0"/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Address to Receive Unstaking Fees (Optional)')}
              tooltipId="receive-fee-address"
              tipsText={CreateOrEditPoolTips.addressToReceiveUnstakingFees}
            />
            <InfoInput  value={receiveFeeAddress} onChange={handleSetReceiveFeeAddress} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Staking Upper Limit per User (Optional)')}
              tooltipId="max-staking-user"
              tipsText={CreateOrEditPoolTips.stakingUpperLimitPerUser}
            />
            <InfoInput  value={maxStakingAmountUser} onChange={handleSetMaxStakingAmountUser} placeholder="0"/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Staking Lower Limit per User (Optional)')}
              tooltipId="min-staking-user"
              tipsText={CreateOrEditPoolTips.stakingLowerLimitPerUser}
            />
            <InfoInput  value={minStakingAmountUser} onChange={handleSetMinStakingAmountUser} placeholder="0"/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Pool Hard Cap (Optional)')}
              tooltipId="the-pool-hard-cap"
              tipsText={CreateOrEditPoolTips.poolHardCap}
            />
            <InfoInput  value={poolHardCap} onChange={handleSetPoolHardCap} placeholder="0"/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Twitter(Optional)')}
              tooltipId="twitter"
              tipsText={CreateOrEditPoolTips.twitter}
            />
            <InfoInput  value={twitter} onChange={handleSetTwitter} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Telegram(Optional)')}
              tooltipId="telegram"
              tipsText={CreateOrEditPoolTips.telegram}
            />
            <InfoInput  value={telegram} onChange={handleSetTelegram} placeholder=""/>
          </StyledInputRow>
          <StyledInputRow>
            <InputLabel
              label={t('Payment Method')}
              tooltipId="payment-option"
              tipsText={CreateOrEditPoolTips.paymentMethod}
            />
            <PaymentSelect
              options={paymentOptionArr}
              onChange={handleSetPaymentOption}
            />
          </StyledInputRow>

          {(!rewardsTokenAllowance ||!paymentTokenAllowance)&&(
            <UiModalBtn
              className={pendingTx || rewardsContract===''||paymentOption===''?'disable':''}
              onClick={handleCreateFun}
            >
              {!rewardsTokenAllowance&&(
                <h3>{t('Approve Reward Token')}</h3>
              )}
              {rewardsTokenAllowance && !paymentTokenAllowance&&(
                <h3>Approve {paymentOption===''?'Payment Token':paymentSymbol}</h3>
              )}
            </UiModalBtn>
          )}

          {rewardsTokenAllowance && paymentTokenAllowance && (
            <UiModalBtn
              className={pendingTx || !userCanCreate ?'disable':''}
              onClick={handleCreateFun}
            >
              <h3>{pendingTx ? t('Loading (10 sec)') : t('Create')} {userCanCreate}</h3>
              {!pendingTx && !userCanCreate&& (
                <h4>{`Insufficient ${paymentSymbol} Balance`} {userCanCreate}</h4>
              )}
              {!pendingTx && userCanCreate&& (
                <h4>{paymentAmount} {paymentSymbol}  {userCanCreate}</h4>
              )}
            </UiModalBtn>
          )}

        </ModalBox>
      </ModalTheme>
    )
  }

const ModalBox = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  padding-right: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 500px;
    max-height: 500px;
  }
`
const StyledInputRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
  }
`



export default CreateModal
