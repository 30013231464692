import React, { lazy } from 'react'
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom'
import { ResetCSS } from 'trustfi-uikit'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/hooks'
import GlobalStyle from './style/Global'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import Home from "./views/Home";
import { useFetchFarmsPublicData } from './state/farms/hooks'
import history from './routerHistory'
import SpaceList from './views/SpaceList'
import SpaceDetail from './views/SpaceDetail'

// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))

const App: React.FC = () => {
  useEagerConnect()
  usePollBlockNumber()
  useFetchFarmsPublicData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/space" exact>
            <SpaceList />
          </Route>
          <Route path="/space/:name" exact>
            <SpaceDetail />
          </Route>
          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </SuspenseWithChunkError>
      <ToastListener />
    </Router >
  )
}

export default React.memo(App)
