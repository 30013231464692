import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import ModalTheme from '../CreatePool/ModalTheme'
import { UiModalBtn } from '../../../components/UiLayout'

interface ModalProps {
  onDismiss?: () => void
}

const FinishPoolTipModal: React.FC<ModalProps> =
  ({
     onDismiss,
   }) => {
    const { t } = useTranslation()
    return (
      <ModalTheme title="" onDismiss={onDismiss}>
        <ModalBox>
          <StyledInputRow>
            <StyledModalTip>
              {t("Please note that do not stake in a finished pool. ")}
            </StyledModalTip>
          </StyledInputRow>
          <StyledInputRow
            className="flex-direction"
            style={{justifyContent:'center'}}
          >
            <UiModalBtn className="width-auto" onClick={onDismiss}>
              <h3>{t('Confirm')}</h3>
            </UiModalBtn>
          </StyledInputRow>
        </ModalBox>
      </ModalTheme>
    )
  }

const ModalBox = styled.div`
  width: 100%;
  overflow: auto;
  padding-right: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 300px;
  } 
`
const StyledModalTip = styled.div`
  font-size: 18px;
  color: ${({theme})=>theme.expandColor.color.white};
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
  line-height: 1.4;
`
const StyledInputRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
  } 
  &.flex-direction  {
    flex-direction: column-reverse;
    ${({ theme }) => theme.mediaQueries.sm} {
      flex-direction: row;
    } 
  }
`

export default FinishPoolTipModal
