import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from 'state/actions'
import { harvest } from 'utils/callHelpers'
import { useMasterchef, useStakingFactoryContract } from '../../../hooks/useContract'
import { useFarms } from '../../../state/farms/hooks'

export const useHarvest = (factoryAddress: string,farmPid: number) => {
  const dispatch = useAppDispatch()
  const { account ,chainId} = useWeb3React()
  const stakingFactoryContract = useStakingFactoryContract(factoryAddress)
  const {data:farms} = useFarms()

  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(stakingFactoryContract, farmPid, account)
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchFarmUserDataAsync({account,farms}))
    return txHash
  }, [account, dispatch, farmPid, stakingFactoryContract,farms])

  return { onReward: handleHarvest }
}

export default null

