import styled from 'styled-components'
import Container from "./Container";

export const UiContainer = styled(Container)`
    max-width: 1200px;
    box-sizing: border-box;
    ${({ theme }) => theme.mediaQueries.sm}{
        padding: 0 15px;
    }
`

export const UiColorLine = styled.div`
  width: 50px;
  height: 1px;
  margin-bottom: 15px;
  background: #009b4c;
`
export const UiHeaderCenter = styled.header`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 0;
    ${({ theme }) => theme.mediaQueries.sm}{
        padding: 30px 0;
    }
`

export const UiColLg3 = styled.div`
    padding: 10px;
    width: 100%;
    ${({ theme }) => theme.mediaQueries.sm}{
        width: 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
`
export const UiColLg4 = styled.div`
    padding: 10px;
    width: 100%;
    ${({ theme }) => theme.mediaQueries.sm}{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
`

export const UiH4 = styled.h4`
    color: #009b4c;
  font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.5;
`
export const UiH4Title = styled(UiH4)`
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  color: #009b4c;
`
export const UiP = styled.p`
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
  color: #fff;
  text-align: center;
`

export const UiActionBtn =styled.div`
  cursor: pointer;
  border-radius: 50px;
  height: 24px;
  font-size: 14px;
  width: fit-content;
  padding:0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme})=>theme.expandColor.color.color4};
  background: ${({theme})=>theme.expandColor.color.color4};
  &:hover{
    color: ${({theme})=>theme.expandColor.color.color1};
    background: ${({theme})=>theme.expandColor.color.color3};
  }
  ${({ theme }) => theme.mediaQueries.lg} {
     padding:0 20px;
     height: 30px;
     font-size: 16px;

  }
`

export const UiText1 = styled.div`
  color: ${({theme})=>theme.expandColor.color.color1};
  font-size: 18px;
`
export const UiWrapper = styled.div`
  min-width: 60px;
  text-align: right;
  ${({ theme }) => theme.mediaQueries.xl} {
     text-align: right;
  }
`

export const UiCol1 = styled.div`
  width: 8.3333%;
  flex: 0 0 8.3333%;
`
export const UiCol2 = styled.div`
  width: 16.666%;
  flex: 0 0 16.666%;
`
export const UiCol3 = styled.div`
  width: 24.999%;
  flex: 0 0 24.999%;
`
export const UiCol4 = styled.div`
  width: 33.333%;
  flex: 0 0 33.333%;
`
export const UiCol5 = styled.div`
  width: 41.666%;
  flex: 0 0 41.666%;
`
export const UiCol6 = styled.div`
  width: 49.999%;
  flex: 0 0 49.999%;
`
