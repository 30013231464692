import styled from 'styled-components'
import React from "react";
import { ModalProps } from "trustfi-uikit";

const ModalTheme: React.FC<ModalProps> = ({
   title,
   onDismiss,
   onBack,
   children,
   hideCloseButton = false,
   bodyPadding = "24px",
   headerBackground = "transparent",
   minWidth = "360px",
   color,
   ...props
 }) => (
  <StyledModal>
    {title!=='' && (
      <StyledHeading>{title}</StyledHeading>
    )}
    {children}
  </StyledModal>
);

export default ModalTheme;

const StyledModal = styled.div`
  border: 4px solid ${({theme})=>theme.expandColor.color.active};
  padding: 30px 20px;
  padding-right: 10px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.modal.background};
  z-index: ${({ theme }) => theme.zIndices.modal};
  border-radius: ${({theme})=>theme.expandColor.radius.radius4};
  width: 100%;
  min-width: 300px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    min-width: 360px;
    max-width: 100%;
    padding: 50px;
  }
`
const StyledHeading = styled.div`
  width: 90%;
  height: 32px;
  border-radius: ${({theme})=>theme.expandColor.radius.radius4};
  border: 4px solid ${({theme})=>theme.expandColor.color.active};
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: ${({theme})=>theme.expandColor.color.white};
  background: ${({ theme }) => theme.modal.background};
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: auto;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 56px;
    font-size: 28px;
    top: -32px;
  }
`
