import { PoolSpaceData } from '../types'

const getPoolSpace = async ():Promise<any> => {
  const url = "https://raw.githubusercontent.com/Sitcum/space-config/main/space.json"

  try {
    const response = await fetch(url,{
      // mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    if (!response.ok) {
      console.error(response)
      return {
        "BSC":[],
        "ETH":[],
        "polygon":[],
        "AVAX": [],
        "OEC": [],
        "KCC": [],
        "Fantom": [],
        "HECO": [],
        "GateChain": [],
      }
    }
    const data = await response.json()
    return data
  }catch (e) {
    console.error('can not get the config.json')
  }
  return {
    "BSC":[],
    "ETH":[],
    "polygon":[],
    "AVAX": [],
    "OEC": [],
    "KCC": [],
    "Fantom": [],
    "HECO": [],
    "GateChain": [],
  }
}

export const fetchSpace = async ()=>{
  const curChainName = process.env.REACT_APP_CHAIN_NAME
  const spaceData = await getPoolSpace()
  return spaceData[curChainName]
}

