import multicall from '../utils/multicall'
import erc20 from '../vipswap/lib/abi/erc20.json'

export const getTokenDecimals = async(addressV: string)=>{
  const calls = [
    {
      address: addressV,
      name: 'decimals',
      params: [],
    },
  ]

  const [
    tokenDecimalsV,
  ] = await multicall(erc20, calls)
  return   tokenDecimalsV[0]

}
export default  null
