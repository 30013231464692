export const getShortAddress = (address: string) => {
  return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
}

export const getExplorerUrl = (chainId: number) => {
  switch (chainId) {
    case 56: return 'https://bscscan.com';
    case 97: return 'https://testnet.bscscan.com';
    case 43114: return 'https://snowtrace.io';
    case 137: return 'https://polygonscan.com';
    default:  return 'https://bscscan.com';
  }
}
export default null
