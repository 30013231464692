import getTimePeriods from '../../utils/getTimePeriods'

export const padTime = (num: number) => num.toString().padStart(2, '0')

export const formatRoundTime = (secondsBetweenBlocks: number) => {
  const { years,months,days,hours, minutes, seconds } = getTimePeriods(secondsBetweenBlocks)
  const minutesSeconds = `${padTime(minutes)}:${padTime(seconds)}`

  if (years > 0) {
    return `in ${years} years`
  }

  if (months > 1) {
    return `in ${months} months`
  }
  if (months > 0 && months<=1) {
    return `in ${months} month`
  }

  if (days > 1) {
    return `in ${days} days`
  }
  if (days > 0 && days <= 1) {
    return `in ${days} day`
  }

  if (hours > 0) {
    return `${padTime(hours)}:${minutesSeconds}`
  }

  return minutesSeconds
}

