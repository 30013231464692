import React, { useEffect, useState } from 'react'
import { useModal, useWalletModal } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import CreateModal from './CreateModal'
import { UiLuminousBtn } from '../../../components/UiLayout'
import useAuth from '../../../../hooks/useAuth'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { useGetSpaceAdds, useGetSpaceDetail } from '../../../../state/farms/hooks'
import ApplyForSpaceModal from './ApplyForSpaceModal'

interface props {
  createBtnType?: string
}
const CreatePoolBtn: React.FC<props> = ({createBtnType}) => {
  const { t } = useTranslation()
  const {spaceAdds} = useGetSpaceAdds()
  const [onPresentCreateModal] = useModal(
    <CreateModal />,
  )
  const [onPresentApplyForSpaceModal] = useModal(
    <ApplyForSpaceModal />,
  )

  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const [hasSpace,setHasSpace] = useState(false)
  useEffect(()=>{
    if(account){
      setHasSpace(spaceAdds.includes(account.toLowerCase()))
    }
  },[account,spaceAdds])
  const {spaceDetail} = useGetSpaceDetail(account??'0x')

  const handleClickButton = (event): void => {
    event.stopPropagation()
    switch (createBtnType) {
      case 'Feature': onPresentApplyForSpaceModal();
        break;
      default: onPresentCreateModal();
        break;
    }
  }
  const renderContent = (): JSX.Element=>{
    switch (createBtnType) {
      case 'Feature': return hasSpace?(
          <StyledLink to={`/space/${spaceDetail?.spaceKey}`}>
            {t('Create Pool')}
          </StyledLink>
        ):(
          <UiLuminousBtn onClick={account?handleClickButton:onPresentConnectModal} className="flex-1 mr-16">
            {t('Create Pool')}
          </UiLuminousBtn>
        )
      default: return (
          <UiLuminousBtn onClick={account?handleClickButton:onPresentConnectModal} className="flex-1 mr-16">
            {t('Create Pool')}
          </UiLuminousBtn>
        );
    }

  }

  return renderContent()
}

const StyledLink = styled(Link)`
  color: ${({theme})=>theme.expandColor.color.active};
  height: 32px;
  padding: 0 15px;
  border-radius:  ${({theme})=>theme.expandColor.radius.radius1};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  text-shadow: 0 0 6px ${({theme})=>theme.expandColor.color.active};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  margin-right: 16px;
  flex: 1;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 38px;
    padding: 0 20px;
    font-size: 14px;
  } 
`

export default CreatePoolBtn
