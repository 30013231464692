import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { ChevronDownIcon, useMatchBreakpoints } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'

import StakedAction from './Actions/StakedAction'
import HarvestAction from './Actions/HarvestAction'
import useBlockCountdown from '../../../../hooks/useGetBlockCountdown'
import { formatRoundTime } from '../../../components/helper'
import { FarmWithStakedValue } from '../FarmCard/FarmCard'
import EditPoolBtn from '../EditPool/EditPoolBtn'

interface ActionbtnProps {
  details: FarmWithStakedValue
  userDataReady: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
`

const ComeSoon = styled.div`
  font-size: 18px;
  flex: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme})=>theme.expandColor.color.active};
  font-weight: 400;
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  padding: 0 15px;
  height: 32px;
  font-size: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 38px;
    padding: 0 20px;
    font-size: 14px;
  } 
`

const Actionbtn: React.FC<ActionbtnProps> = ({ details,userDataReady}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const { startBlock,poolOwner } = details
  const secondsRemaining = useBlockCountdown(startBlock)
  // const secondsRemaining = useBlockCountdown(14926640)
  const countdown = formatRoundTime(secondsRemaining)

  return (
    <Container>
      {account &&  account.toLowerCase()===poolOwner.toLowerCase() &&details.endBlock===0 &&  secondsRemaining>0&&(
        <EditPoolBtn farm={details} />
      )}
      {details.endBlock===0 && secondsRemaining>0&&(
        <ComeSoon>
          {countdown}
        </ComeSoon>
      )}
      {( details.endBlock>0 || secondsRemaining<=0 )&&(
        <HarvestAction {...details} userDataReady={userDataReady} />
      )}
      {(details.endBlock>0 || secondsRemaining<=0 )&&(
        <StakedAction detail={details} userDataReady={userDataReady} />
      )}
    </Container>
  )
}

export default Actionbtn
