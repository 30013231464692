import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import BigNumber from 'bignumber.js'
import { fetchFarmUserDataAsync } from 'state/actions'
import { approve } from 'utils/callHelpers'
import {
  useLpContract,
  useMasterchef,
  useStakingFactoryContract,
  useStakingFactoryCreatorContract,
} from '../../../hooks/useContract'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { getBep20Contract } from '../../../utils/contractHelpers'
import { useFarms } from '../../../state/farms/hooks'

// Approve a Farm
export const useApprove = (lpAddress: string) => {
  const dispatch = useAppDispatch()
  const { account,chainId } = useWeb3React()
  const masterChefContract = useMasterchef()
  const lpContract = useLpContract(lpAddress)
  const {data:farms} = useFarms()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync({account,farms}))
      return tx
    } catch (e) {
      console.error(e)
      return false
    }
  }, [account, dispatch, lpContract, masterChefContract,farms])

  return { onApprove: handleApprove }
}

// approve token to stakingFactoryCreator Contract
export const useApproveTokenToCreate = () =>{
  const { account,chainId } = useWeb3React()
  const stakingFactoryCreatorContract = useStakingFactoryCreatorContract()
  const { library } = useActiveWeb3React()

  // approve
  const handleApprove = useCallback(async (token: string) => {
    try {
      const tokenContract = getBep20Contract(token,library.getSigner())
      const tx = await approve(tokenContract, stakingFactoryCreatorContract, account)
      return tx
    } catch (e) {
      console.error(e)
      return false
    }
  }, [account, library, stakingFactoryCreatorContract])

  // approve checked
  const handleAllowances = useCallback(async (token: string) => {
    if(token===''||token==='-') return false
    try {
      const tokenContract = getBep20Contract(token,library.getSigner())
      const res = await tokenContract.allowance(
        account, stakingFactoryCreatorContract.address
      )
      const allowance = new BigNumber(res.toString())
      return await allowance.isGreaterThan(0)
    } catch (e) {
      console.error(e)
      return false
    }
  }, [account, library, stakingFactoryCreatorContract])

  return {
    onApproveTokenToFactory: handleApprove,
    onAllowanceToken: handleAllowances,
  }
}

// approve token to stakingFactory Contract
export const useApproveTokenToFactory = (factoryAddress: string) =>{
  const { account } = useWeb3React()
  const stakingFactoryContract = useStakingFactoryContract(factoryAddress)
  const { library } = useActiveWeb3React()

  // approve
  const handleApprove = useCallback(async (token: string) => {
    try {
      const tokenContract = getBep20Contract(token,library.getSigner())
      const tx = await approve(tokenContract, stakingFactoryContract, account)
      return tx
    } catch (e) {
      console.error(e)
      return false
    }
  }, [account, library, stakingFactoryContract])

  // approve checked
  const handleAllowances = useCallback(async (token: string) => {
    if(token==='') return false
    try {
      const tokenContract = getBep20Contract(token,library.getSigner())
      const res = await tokenContract.allowance(
        account, factoryAddress
      )
      const allowance = new BigNumber(res.toString())
      return await allowance.isGreaterThan(0)
    } catch (e) {
      console.error(e)
      return false
    }
  }, [account, library,factoryAddress])

  return {
    onApproveTokenToFactory: handleApprove,
    onAllowanceToken: handleAllowances,
  }
}

