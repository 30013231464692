import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../contexts/Localization'


const Footer:React.FC = ()=>{
  const { t } = useTranslation()

  return (
    <StyledBox>
      <StyledRow>

        {/* trustFi */}
        <StyledColLg4>
          <StyledLogo>
            <img src="/images/logo/logo4.png" alt=""/>
          </StyledLogo>
          <StyledDesc>
            TrustFi focuses on early crypto assets issuance, liquidity management, community activities and DAO governance to unlock the potential of DeFi.
          </StyledDesc>
          <StyledTitle>
            Buy $TFI
          </StyledTitle>
          <a href="https://pancakeswap.finance/swap?outputCurrency=0x7565ab68d3f9dadff127f864103c8c706cf28235" rel="noreferrer" target="_blank">
            <StyledBuyBtn>
              <img className="icon" alt="PancakeSwap" src="/images/footer/swap/pancakeswap.png"/>
              <div className="name">TFI/BNB</div>
            </StyledBuyBtn>
          </a>
          <a href="https://www.mexc.com/exchange/TRUSTFI_USDT" rel="noreferrer" target="_blank">
            <StyledBuyBtn>
              <img className="icon" alt="MXC" src="/images/footer/swap/mxc.svg"/>
              <div className="name">TRUSTFI/USDT</div>
            </StyledBuyBtn>
          </a>
        </StyledColLg4>


        {/* about us */}
        <StyledColLg4>
          <StyledTitle className='layout'>
            {t('About Us')}
          </StyledTitle>
          <StyledLabel>
            <a href="https://docs.trustfi.org/" rel="noreferrer" target="_blank">
              {t('Whitepaper')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <a href="https://www.certik.com/projects/trustfinetwork" rel="noreferrer" target="_blank">
              {t('Smart Contract Audits')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <a href="https://trustfi.medium.com/" rel="noreferrer" target="_blank">
              {t('Blog')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <a href="https://medium.com/trustfi/attention-trustfi-is-hiring-435118c385fa" rel="noreferrer" target="_blank">
              {t('Join Us')}
            </a>
          </StyledLabel>
        </StyledColLg4>

        {/* Telegram Community */}
        <StyledColLg4>
          <StyledTitle className='layout'>
            {t('Telegram Community')}
          </StyledTitle>
          <StyledLabel>
            <img src="/images/footer/community/usa.png" className="community-icon" alt=""/>
            <a href="https://t.me/trustfi_fans" rel="noreferrer" target="_blank">
              {t('English Community')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/turkish.png" className="community-icon" alt=""/>
            <a href="https://t.me/TrustFiTurkey" rel="noreferrer" target="_blank">
              {t('Turkish Community')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/indonesian.png" className="community-icon" alt=""/>
            <a href="https://t.me/TrustFiIDN" rel="noreferrer" target="_blank">
              {t('Indonesian Community')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/vietnam.png" className="community-icon" alt=""/>
            <a href="https://t.me/tfivngroup" rel="noreferrer" target="_blank">
              {t('Vietnamese Community')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/korean.png" className="community-icon" alt=""/>
            <a href="https://open.kakao.com/o/grQCY7Kd" rel="noreferrer" target="_blank">
              {t('Korean Community (Kakao)')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/france.png" className="community-icon" alt=""/>
            <a href="https://t.me/trustfi_francais" rel="noreferrer" target="_blank">
              {t('French Community')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/community/china.png" className="community-icon" alt=""/>
            <a href="https://t.me/trustfichinese" rel="noreferrer" target="_blank">
              {t('Chinese Community')}
            </a>
          </StyledLabel>
        </StyledColLg4>

        {/* Our Social Network */}
        <StyledColLg4>
          <StyledTitle className='layout'>
            {t('Our Social Network')}
          </StyledTitle>
          <StyledLabel>
            <img src="/images/footer/social/twitter.png" className="social-icon" alt=""/>
            <a href="https://twitter.com/trustfiorg" rel="noreferrer" target="_blank">
              {t('TrustFi on Twitter')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/social/medium.png" className="social-icon" alt=""/>
            {/*  https://medium.com/@trustfi */}
            <a href="https://blog.trustfi.org/" rel="noreferrer" target="_blank">
              {t('TrustFi on Medium')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/social/linkedin.png" className="social-icon" alt=""/>
            <a href="https://www.linkedin.com/company/trustfi" rel="noreferrer" target="_blank">
              {t('TrustFi on Linkedin')}
            </a>
          </StyledLabel>
          <StyledLabel>
            <img src="/images/footer/social/telegram.png" className="social-icon" alt=""/>
            <a href="https://t.me/trustfi_channel" rel="noreferrer" target="_blank">
              {t('TrustFi on Telegram')}
            </a>
          </StyledLabel>
        </StyledColLg4>

      </StyledRow>
    </StyledBox>
  )
}

const StyledBox = styled.div`
  width: 100%;
  border-top: 9px solid ${({theme})=>theme.expandColor.color.active};
  box-sizing: border-box;
  padding: 24px 0;
  color: ${({theme})=>theme.expandColor.color.white};
  ${({ theme }) => theme.mediaQueries.sm}{
    padding: 48px 0;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width:  100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0 15px;
`
const StyledColLg4 = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 25%;
  }
  &.layout {
    padding: 0 15px;
    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 0 40px;
    }
  }
`
const StyledLogo = styled.div`
  width: 100%;
  & img {
    width: 100%;
    max-width: 300px;
    height: 100%;
    object-fit:  contain;
  }
`
const StyledDesc = styled.div`
  font-size: 15px;
  line-height: 1.2;
  margin: 20px 0;
`

const StyledTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  box-sizing:  border-box;
  padding-bottom: 20px;
  &.layout {
    padding-top: 20px;
    background: ${({theme})=>theme.expandColor.color.active};
    color: ${({theme})=>theme.expandColor.color.black};
    margin: 10px -30px;
    padding-left:  30px;
    padding-right: 30px;
    ${({ theme }) => theme.mediaQueries.lg} {
      padding-top: 60px;
      background: transparent;
      color: ${({theme})=>theme.expandColor.color.white};
      margin: 0;
      padding-left:  0px;
      padding-right: 0px;
    }
  }

`
const StyledBuyBtn = styled.div`
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  box-sizing: border-box;
  padding: 6px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 64px;
  margin-top: 10px;

  & .icon {
    width: auto;
    height: 24px;
    object-fit: contain;
  }

  & .name {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }
`

const StyledLabel = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;

  & .community-icon {
    width: 30px;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 5px;
  }
  & .social-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    vertical-align: middle;
    margin-right: 5px;
  }
`
export default Footer
