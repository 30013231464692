import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import Collapse from "@material-ui/core/Collapse";
import { useSnackbar } from 'notistack'
import BigNumber from 'bignumber.js'

import ModalTheme from '../CreatePool/ModalTheme'
import { useFarms, useFetchFarmsPublicData, useFetchFarmUserData } from '../../../../state/farms/hooks'
import { Farm } from '../../../../state/types'
import { useAppDispatch } from '../../../../state'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../../../../state/farms'
import useClosePool from '../../hooks/useClosePool'
import { UiModalBtn } from '../../../components/UiLayout'
import { PaymentSelectOptionProps } from '../CreatePool/PaymentSelect'
import useGetUserCommTokens from '../../hooks/useGetUserCommTokens'

interface ModalProps {
  onDismiss?: () => void
  farm: Farm
}

const ClosePoolModal: React.FC<ModalProps> =
({
  onDismiss,
  farm
}) => {
  const { t } = useTranslation()
  const {account} = useWeb3React()

  const {userSupportCommTokens} = useFarms()
  const supportCommToken = userSupportCommTokens.find((item)=>
    item.supportCommToken.toLowerCase()===farm.commissionTokenAddress.toLowerCase())
  const [userCanClose,setUserCanClose] = useState(false)
  useEffect(()=>{
    if( supportCommToken && new BigNumber(farm.poolCloseFeeValue).lte(new BigNumber(supportCommToken.userBalance))){
      setUserCanClose(true)
    }
  },[
    supportCommToken,setUserCanClose,farm
  ])

  const [pendingTx, setPendingTx] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch()
  const {data:poolList} = useFarms()

  const {onClosePool} = useClosePool()
  const handleClosePoolFun = async()=>{
    if(pendingTx) return
    setPendingTx(true)
    const res = await onClosePool(farm.pid)
    if(res){
      enqueueSnackbar('Close Pool Successfully', {
        variant:'success' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchFarmUserDataAsync({account:account.toString(),farms:poolList}))
      onDismiss()
    }
    setPendingTx(false)
  }

  return (
    <ModalTheme title="Close Pool" onDismiss={onDismiss}>
      <ModalBox>
        <StyledInputRow>
          <StyledModalTip>
            {t('You want to close this pool. Did you confirm?')}
            <br/>
            {t('The reward tokens will automatically refund to your creator address once the pool closed.')}
          </StyledModalTip>
        </StyledInputRow>
        <StyledInputRow
          className="flex-direction"
        >
          <UiModalBtn
            className="mr back-color "
            onClick={()=>onDismiss()}
          >
            <h3>{t('No')}</h3>
          </UiModalBtn>
          <UiModalBtn
            className={`${pendingTx || !userCanClose  ?'disable':''} mb`}
            onClick={handleClosePoolFun}
          >
            <h3>{t('Yes')}</h3>
            {!pendingTx && !userCanClose && (
              <h4>{`Insufficient ${farm.commissionTokenSymbol} Balance`}</h4>
            )}
            {!pendingTx && userCanClose && (
              <h4>{farm.poolCloseFeeValue} {farm.commissionTokenSymbol}</h4>
            )}

          </UiModalBtn>
        </StyledInputRow>
      </ModalBox>
    </ModalTheme>
  )
}

const ModalBox = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  padding-right: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 500px;
    max-height: 500px;
  } 
`
const StyledModalTip = styled.div`
  font-size: 18px;
  text-align: center;
  color: ${({theme})=>theme.expandColor.color.white};
  width: 100%;
  flex: 1;
  margin-bottom: 10px;
`
const StyledInputRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
  } 
  &.flex-direction  {
    flex-direction: column-reverse;
    ${({ theme }) => theme.mediaQueries.sm} {
      flex-direction: row;
    } 
  }
`

export default ClosePoolModal
