import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PoolSpaceData } from 'state/types'


interface props {
  spaceDetail: PoolSpaceData
}
const SpaceItem:React.FC<props> = ({spaceDetail})=>{
  return (
    <StyledItemBox>
      <Link to={`/space/${spaceDetail.spaceKey}`}>
        <StyledItem>
          <img src={spaceDetail.logoUrl} alt=""/>
          <h3>
            {spaceDetail.spaceName}
          </h3>
        </StyledItem>
      </Link>
    </StyledItemBox>
  )
}

const StyledItemBox = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding: 15px;
  ${({theme})=>theme.mediaQueries.md} {
    width: 20%;
  }
`
const StyledItem = styled.div`
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  & img {
    max-width: 115px;
    max-height: 115px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
  & h3 {
    color: ${({theme})=>theme.expandColor.color.white};
    font-size: 20px;
    line-height: 32px;
    margin-top: 15px;
  }
`
export default SpaceItem
