import React from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { useFarms, useFetchFarmsPublicData, useFetchFarmUserData } from 'state/farms/hooks'
import { PageMaxWidth } from 'components/layout/Page'
import TopBanner from './components/TopBanner'
import NotFound from '../NotFound'
import FarmsContent from '../Farms/FarmsContent'
import PageLoading from '../components/PageLoading'

interface paramsProps {
  name: string,
}

const SpaceDetail:React.FC = ()=>{
  // get url params
  const {name} = useParams<paramsProps>()
  const { data: farmsLPList,spaceList,spaceLoaded } = useFarms()
  const { t } = useTranslation()
  const history = useHistory()
  history.listen(route => {
    window.scrollTo(0,0)
  });
  useFetchFarmsPublicData()
  useFetchFarmUserData()
  const spaceFind = spaceList.find((item)=>item.spaceKey.toLowerCase()===name.toLowerCase())

  return spaceFind?(
    <PageMaxWidth>
      <TopBanner detail={spaceFind}/>
      <FarmsContent farmsLPList={farmsLPList} viewModeKey="space_featured_view" farmHeadTitle="Featured Pools" spaceAddress={spaceFind.ownerAddress}/>
    </PageMaxWidth>
  ):(
    <PageLoading loaded={spaceLoaded} />
  )
}

export default SpaceDetail
