import React from 'react'
import styled, { keyframes,css } from 'styled-components'
import { ChevronDownIcon, ChevronUpIcon, Text } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'

export interface ExpandableSectionButtonProps {
  onClick?: () => void
  expanded?: boolean
}

const expandAnimation = keyframes`
  
  from {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  to {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`

const collapseAnimation = keyframes`
  from {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  to {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
const Wrapper = styled.div<{expanded:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // margin: 24px -24px -24px;
  box-sizing: border-box;
  padding: 15px;
  // border-bottom-left-radius: ${({ theme,expanded }) => expanded?'0px':theme.expandColor.radius.radius5};
  // border-bottom-right-radius: ${({ theme,expanded }) => expanded?'0px':theme.expandColor.radius.radius5};
  background-color: ${({theme})=>theme.expandColor.color.active};
  color: ${({theme})=>theme.expandColor.color.black};
  animation: ${({ expanded }) =>
  expanded
    ? css`
          ${expandAnimation} 300ms linear forwards
        `
    : css`
          ${collapseAnimation} 300ms linear forwards
        `};

  svg {
    fill: ${({ theme }) => theme.expandColor.color.black};
  }
`

const ExpandableSectionButton: React.FC<ExpandableSectionButtonProps> = ({ onClick, expanded }) => {
  const { t } = useTranslation()

  return (
    <Wrapper expanded={expanded} aria-label="Hide or show expandable content" role="button" onClick={() => onClick()}>
      <Text color="black" bold fontSize="18px">
        {expanded ? t('Hide') : t('Details')}
      </Text>
      {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Wrapper>
  )
}

ExpandableSectionButton.defaultProps = {
  expanded: false,
}

export default ExpandableSectionButton
