import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ChevronDownIcon, useMatchBreakpoints } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'

interface DetailsProps {
  actionPanelToggled: boolean
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
`

const ArrowIcon = styled(ChevronDownIcon)<{ toggled: boolean }>`
  transform: ${({ toggled }) => (toggled ? 'rotate(180deg)' : 'rotate(0)')};
  height: 20px;
  width: 20px;
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 30px;
    width: 30px;
  }
`

const Details: React.FC<DetailsProps> = ({ actionPanelToggled}) => {
  const { t } = useTranslation()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <Container>
      {false && !isMobile && t('Details')}
      <ArrowIcon color="white" toggled={actionPanelToggled} />
    </Container>
  )
}

export default Details
