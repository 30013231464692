import { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { BIG_ZERO } from 'utils/bigNumber'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, setBlock } from '../actions'
import { State, Farm, FarmsState } from '../types'
import {
  fetchCommonPoolConfig,
  fetchPoolSpaceDataAsync,
  fetchSupportCommTokensAsync,
  fetchUserSupportCommTokensAsync,
} from './index'

export const useFetchFarmsPublicData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchSupportCommTokensAsync())
    dispatch(fetchPoolSpaceDataAsync())
    dispatch(fetchCommonPoolConfig())
  }, [dispatch, slowRefresh])
}

export const useFetchFarmUserData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const {account:accountV} = useWeb3React()
  const {data:farmsV,supportCommTokens} = useFarms()
  useEffect(() => {
    if(accountV && farmsV.length){
      dispatch(fetchFarmUserDataAsync({farms:farmsV,account:accountV}))
    }
  }, [dispatch, slowRefresh,accountV,farmsV])
  useEffect(() => {
    if(accountV && supportCommTokens.length && supportCommTokens[0].supportCommToken!==''){
      dispatch(fetchUserSupportCommTokensAsync({tokens:supportCommTokens,account:accountV}))
    }
  }, [dispatch, slowRefresh,accountV,supportCommTokens])
}

// Farms
export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => state.farms)
  return farms
}

export const useFarmFromPid = (pid:number): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmUser = (poolId:number) => {
  const farmUser = useSelector((state: State) => state.farms.userData.find((f) => f.pid === poolId))
  return farmUser?{
    pid: farmUser.pid,
    allowance: new BigNumber(farmUser.allowance),
    balance: new BigNumber(farmUser.balance),
    stakeAmount: new BigNumber(farmUser.stakeAmount),
    stakePower: new BigNumber(farmUser.stakePower),
    reward:new BigNumber(farmUser.reward),
    userMinStakeAmount: farmUser.userMinStakeAmount,
    userMaxStakeAmount: farmUser.userMaxStakeAmount,
    lastStakeTime: farmUser.lastStakeTime,
  }:{
    pid: poolId,
    allowance: BIG_ZERO,
    balance: BIG_ZERO,
    stakeAmount: BIG_ZERO,
    stakePower: BIG_ZERO,
    reward: BIG_ZERO,
    userMinStakeAmount: undefined,
    userMaxStakeAmount: undefined,
    lastStakeTime: 0,
  }
}

export const useGetSpaceAdds = ()=>{
  const spaceList = useSelector((state: State) => state.farms.spaceList)
  const spaceAddsV = spaceList.map((item)=>{
    return item.ownerAddress.toLowerCase()
  })
  return {spaceAdds: spaceAddsV}
}
export const useGetSpaceDetail = (address: string)=>{
  const spaceList = useSelector((state: State) => state.farms.spaceList)
  const detail = spaceList.find((item)=>{
    return item.ownerAddress.toLowerCase()===address.toLowerCase()
  })
  return {spaceDetail: detail}
}
