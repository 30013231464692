import React from 'react'
import { Button, useWalletModal } from 'trustfi-uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'

import { useGetBnbBalance } from '../hooks/useTokenBalance'
import { walletSet } from './Menu/config'
import tokens from '../config/constants/tokens'
import useActiveWeb3React from '../hooks/useActiveWeb3React'
import { DEFAULT_TOKEN_DECIMAL } from '../config'

const UnlockButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const tokenBalanceData = useGetBnbBalance()
  const { account, chainId } = useActiveWeb3React()
  const tokenBalance = new BigNumber(tokenBalanceData.balance).div(DEFAULT_TOKEN_DECIMAL).toNumber().toLocaleString('en-US', { maximumFractionDigits: 3 })

  // const { onPresentConnectModal } = useWalletModal(login, logout, account, walletSet.helpLink, walletSet.scanLink, walletSet.scanLabel,tokenBalance,tokens.bnb.symbol);

  const { onPresentConnectModal } = useWalletModal(login, logout)
  return (
    <StyledButton onClick={onPresentConnectModal} {...props}>
      {t('Unlock Wallet')}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  color: ${({theme})=>theme.expandColor.color.black};
`
export default UnlockButton
