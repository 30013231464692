import React from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'contexts/Localization'
import { useFetchFarmsPublicData, useFetchFarmUserData } from 'state/farms/hooks'
import Page from 'components/layout/Page'
import SpaceLayout from './components/SpaceLayout'

const SpaceList:React.FC = ()=>{
  const { t } = useTranslation()
  useFetchFarmsPublicData()
  useFetchFarmUserData()
  const history = useHistory()
  history.listen(route => {
    window.scrollTo(0,0)
  });
  return (
    <Page>
      <SpaceLayout/>
    </Page>
  )
}

export default SpaceList
