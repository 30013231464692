import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

interface SelectProps {
  poolType: number
  onChange?: (option: number) => void
}

const PoolType: React.FunctionComponent<SelectProps> = ({ poolType, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedType, setSelectedType] = useState(poolType)

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (option: number) => () => {
    setSelectedType(option)
    if (onChange) {
      onChange(option)
    }
  }

  return (
    <Container>
      <BtnItem
        className={selectedType===0?'active':''}
        onClick={onOptionClicked(0)}
      >Token</BtnItem>
      <BtnItem
        className={selectedType===1?'active':''}
        onClick={onOptionClicked(1)}
      >LP</BtnItem>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`
const BtnItem = styled.div`
  color: ${({theme})=>theme.expandColor.color.active};
  height: 32px;
  padding: 0 15px;
  margin-right: 10px;
  border-radius:  ${({theme})=>theme.expandColor.radius.radius3};
  min-width: 80px;
  box-sizing: border-box;
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  background: ${({theme})=>theme.expandColor.color.pageBg};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.active {
    background: ${({theme})=>theme.expandColor.color.active};
    color: ${({theme})=>theme.expandColor.color.black};
  }
  &:hover {
    background: ${({theme})=>theme.expandColor.color.active};
    color: ${({theme})=>theme.expandColor.color.black};
  }
`

export default PoolType
