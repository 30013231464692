import React, { useState, useMemo } from 'react'
import { Input } from 'trustfi-uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'

const StyledInput = styled(Input)`
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  margin-left: auto;
  background-color: ${({theme})=>theme.expandColor.color.pageBg};
  height: 32px; 
  color: ${({theme})=>theme.expandColor.color.active};
  &::placeholder {
    color: ${({theme})=>theme.expandColor.color.color3};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    box-shadow: none;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 38px;
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

const Container = styled.div`
  flex: 1;
`

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string
  onBlurFun?: ()=>void
  value?: string
}

const InfoInput: React.FC<Props> = ({ onChange: onChangeCallback,placeholder,onBlurFun,value }) => {
  const [inputText, setInputText] = useState(value)

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <Container>
      <InputWrapper>
        <StyledInput
          value={inputText}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlurFun}
        />
      </InputWrapper>
    </Container>
  )
}

export default InfoInput
