import React from 'react'
import styled from 'styled-components'
import { useFarmUser } from 'state/farms/hooks'
import { useTranslation } from 'contexts/Localization'
import { Text, Image } from 'trustfi-uikit'
import { ReportProblem } from '@material-ui/icons'
import { getBalanceNumber } from 'utils/formatBalance'
import ReactTooltip from 'react-tooltip'

import LpFarmIcon from "../LpFarmIcon/LpFarmIcon";

export interface FarmProps {
  label: string
  pid: number
  image: string
  isErrorPool?: boolean
  rewardTokenImg?: string
  stakedSymbol?: string
  rewardSymbol?: string
}

const IconImage = styled(Image)`
  width: 24px;
  height: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 40px;
    height: 40px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`
const StyledErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  & svg{
    fill: red;
  }
`

const Farm: React.FunctionComponent<FarmProps> = ({
  image,
  label,
  pid,
  isErrorPool,
  rewardTokenImg,
  stakedSymbol,
  rewardSymbol,
}) => {
  const { stakeAmount:stakedBalance } = useFarmUser(pid)
  const { t } = useTranslation()
  const rawStakedBalance = getBalanceNumber(stakedBalance)

  return (
    <Container>
      <LpFarmIcon imgUrl={image} subImg={rewardTokenImg}/>
      <div>
        <Text bold color="white">
          {isErrorPool&& (
            <StyledErrorIcon
              data-for="error-pool"
              data-tip
            >
              <ReportProblem style={{ fontSize: 16 }}/>
            </StyledErrorIcon>
          )}
          {label}
        </Text>
        <Text color="white">
          Stake {stakedSymbol} - Earn {rewardSymbol}
        </Text>
      </div>
      <StyledTooltip
        id="error-pool"
        className="custom-class"
        delayHide={500}
        effect="solid"
      >
        error pool
      </StyledTooltip>
    </Container>
  )
}

const StyledTooltip = styled(ReactTooltip)`
 pointer-events: auto !important;
 opacity: 1 !important;
 &:hover {
  visibility: visible !important;
  opacity: 1 !important;
 }
 &.custom-class{
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
  box-shadow: none;
  max-width: 250px;
  word-break: break-all;
 }
 &.custom-class.place-bottom:after{
  border-bottom-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-top:after{
  border-top-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-left:after{
  border-left-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-right:after{
  border-right-color:  ${({theme})=>theme.expandColor.color.active};
 }
`
export default Farm
