import React, { useEffect, useMemo, useState } from 'react'
import { DateTimePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import debounce from 'lodash/debounce'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { IconButton, InputAdornment } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'

interface Props {
  onChange: (e: Date) => void,
  value?: Date
}

const useStyles = makeStyles({
  picker: {
    "&.MuiFormControl-root": {
      'color':"#fff",
      'width':"100%",
    },
    "& .MuiInput-root": {
      'color':"#fff",
    },
    "& svg": {
      'fill':"#fff"
    },
    "& .MuiIconButton-root": {
      'padding': '0px',
    },
    "& .MuiInput-underline:after":{
      'display':'none',
    }
  }
});

const TimerPicker: React.FC<Props>  = ({onChange:onChangeCallback,value})=>{
  const classes = useStyles();
  const debouncedOnChange = useMemo(
    () => debounce((e: Date) => onChangeCallback(e), 50),
    [onChangeCallback],
  )
  const [selectedDate, handleDateChange] = useState(value??new Date());
  useEffect(()=>{
    handleDateChange(value)
  },[value])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container>
        <Wrapper>
          <DateTimePicker
            className={classes.picker}
            value={selectedDate}
            disablePast
            onChange={(newValue) => {
              handleDateChange(newValue);
              debouncedOnChange(newValue)
            }}
            showTodayButton
            format="yyyy-MM-dd HH:mm"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <DateRange/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Wrapper>
      </Container>
    </MuiPickersUtilsProvider>

  )
}


const Container = styled.div`
  flex: 1;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 16px;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`
export default TimerPicker
