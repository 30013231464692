import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, LinkExternal } from 'trustfi-uikit'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { useTranslation } from 'contexts/Localization'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { Farm } from '../../../state/types'
import { useFarmUser } from '../../../state/farms/hooks'

interface DepositModalProps {
  max: BigNumber
  onConfirm: (amount: string,decimals: number) => Promise<any>
  onDismiss?: () => void
  tokenName?: string
  addLiquidityUrl?: string
  farm: Farm
}

const DepositModal: React.FC<DepositModalProps> = ({ max, onConfirm, onDismiss, tokenName = '', addLiquidityUrl,farm }) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const userFarmData = useFarmUser(farm.pid)
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max,farm.stakeTokenDecimals ,3)
  }, [max,farm])
  const userMaxInput = useMemo(() => {
    if(userFarmData.userMaxStakeAmount&&userFarmData.userMaxStakeAmount!=='0'){
      return getFullDisplayBalance(new BigNumber(userFarmData.userMaxStakeAmount),farm.stakeTokenDecimals,0)
    }
    if(userFarmData.userMaxStakeAmount&&userFarmData.userMaxStakeAmount==='0'&&farm.maxStakeAmount>0){
      return '0'
    }

    return getFullDisplayBalance(max,farm.stakeTokenDecimals ,3)
  }, [max,userFarmData,farm])
  const userMinStakeAmount = getFullDisplayBalance(new BigNumber(userFarmData.userMinStakeAmount),farm.stakeTokenDecimals ,0)

  const valNumber = new BigNumber(val)
  const fullBalanceNumber = new BigNumber(userMaxInput)

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(userMaxInput)
  }, [userMaxInput, setVal])

  return (
    <Modal title={`Stake ${tokenName}`} onDismiss={onDismiss}>
      <ModalInput
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
        addLiquidityUrl={addLiquidityUrl}
        inputTitle={t('Stake')}
        inputTip={userMaxInput==='0'?'Pool has been stake max':`Input Range: ${userMinStakeAmount} ~ ${userMaxInput}`}
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%" disabled={pendingTx}>
          {t('Cancel')}
        </Button>
        <StyledBtn
          width="100%"
          disabled={pendingTx || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(fullBalanceNumber) || valNumber.lt(new BigNumber(userMinStakeAmount))}
          onClick={async () => {
            setPendingTx(true)
            const res = await onConfirm(val,farm.stakeTokenDecimals)
            setPendingTx(false)
            if(res){
              onDismiss()
            }
          }}
        >
          {pendingTx ? t('Pending Confirmation') : t('Confirm')}
        </StyledBtn>
      </ModalActions>
      {/*
      <LinkExternal href={addLiquidityUrl} style={{ alignSelf: 'center' }}>
        {t('Get')} {tokenName}
      </LinkExternal>
      */}
    </Modal>
  )
}

const StyledBtn = styled(Button)`
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
`
export default DepositModal
