import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { UiContainerType2 } from '../../components/UiLayout'
import { useCirculation, useGetTFITotalSupply, useGetTVL } from '../hooks/helpers'
import { useGetTFITokenPrice } from '../../../hooks/useTokenBalance'

const Statistic: React.FC = () => {
  const { t } = useTranslation()
  const tvlData = useGetTVL()
  const tfiPrice = useGetTFITokenPrice()
  const tfiTotalSupply = useGetTFITotalSupply()
  const circulation = useCirculation()
  return (
    <UiContainerType2>
      <StyledContainer>
        <StyledItem className="line">
          <StyledLabel>TFI Price</StyledLabel>
          <StyledValue>{tfiPrice.toNumber().toLocaleString('en-US', { maximumFractionDigits: 2 })}$</StyledValue>
        </StyledItem>
        <StyledItem className="line">
          <StyledLabel>TFI in Circulation</StyledLabel>
          <StyledValue>{circulation.circulation.toLocaleString('en-US', { maximumFractionDigits: 2 })}</StyledValue>
        </StyledItem>
        <StyledItem className="line">
          <StyledLabel>TFI Market Cap</StyledLabel>
          <StyledValue>{circulation.marketCap.toLocaleString('en-US', { maximumFractionDigits: 2 })}$</StyledValue>
        </StyledItem>
        <StyledItem className="line">
          <StyledLabel>Total Value Locked (TVL)</StyledLabel>
          <StyledValue>{tvlData.toLocaleString('en-US', { maximumFractionDigits: 2 })}$</StyledValue>
        </StyledItem>
        <StyledItem>
          <StyledLabel>TFI Total Supply</StyledLabel>
          <StyledValue>{tfiTotalSupply.toLocaleString('en-US', { maximumFractionDigits: 2 })}</StyledValue>
        </StyledItem>
      </StyledContainer>
    </UiContainerType2>
  )
}

const StyledContainer = styled(UiContainerType2)`
  margin-top: 40px;
  border-radius: ${({theme})=>theme.expandColor.radius.radius4};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-wrap: nowrap;
    padding: 26px 0;
  }
`
const StyledItem = styled.div`
  width: 100%;
  padding: 15px 0;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0 15px;
    flex: auto;
  }
  &.line:after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      top: auto;
      width: 150px;
      height: 3px;
      margin: auto;
      border-radius: ${({theme})=>theme.expandColor.radius.radius1};
      background-color: ${({theme})=>theme.expandColor.color.active};
      ${({ theme }) => theme.mediaQueries.sm} {
        top: 0;
        right: -2px;
        bottom: 0;
        left: auto;
        width: 3px;
        height: 25px;
      }
    }
`
const StyledLabel = styled.div`
  font-size: 16px;
  color: ${({theme})=>theme.expandColor.color.white};
  font-weight: 600;
  text-align: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
  }
`
const StyledValue = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: ${({theme})=>theme.expandColor.color.white};
  text-align: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
  }
`
export default Statistic
