import { NFTStorage, File,Blob } from 'nft.storage'
import Resizer from "react-image-file-resizer"
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { useFarms } from '../../../state/farms/hooks'
import { useGetBNBPrice } from '../../../hooks/useTokenBalance'
import { getWbnbAddress } from '../../../utils/addressHelpers'

// upload img file to nft.storage
export const uploadNftStorage = async (
  authorization,
  imgFileData,
  type,
) => {
  const client = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE_API_KEY })
  const cid = await client.storeBlob(new Blob([imgFileData]))
  // console.log('cid',cid)
  return cid
}

// upload img file to nft.storage
export const uploadNftStorageBak = async (
  authorization,
  imgFileData,
  type,
) => {
  const response = await fetch('https://api.nft.storage/upload',{
    method: 'post',
    headers: {
      'accept': 'application/json',
      'Content-Type': type,
      'Authorization':  authorization
    },
    body: imgFileData,
  })
  if (!response.ok) {
    console.error(response.statusText)
    return null
  }

  const data = await response.json()
  return data
}

export const resizeFile = (file) => new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    300,
    "png",
    100,
    0,
    (uri) => {
      resolve(uri)
    },
    "file"
  )
})

export const useGetPoolTVL = (pid: number) => {
  const [num,setNum] = useState(0)
  const { data: farmsLP } = useFarms()
  const bnbPrice = useGetBNBPrice()
  const bnbAddress = getWbnbAddress()

  const fetchPoolTvl = useCallback(async () => {
    let num1 = new BigNumber(0);
    const findPool = farmsLP.find((item)=>item.pid === pid)
    const poolStakeAmount =  new BigNumber(findPool.stakeAmount).div(new BigNumber(10).pow(findPool.stakeTokenDecimals))
    if(findPool.poolType===0){
      const sp = new BigNumber(findPool.poolViewInfo.stakeUsdtPrice)
      if(findPool.stakedPairUsdtAddress.toLowerCase() === bnbAddress.toLowerCase()){
        num1 = num1.plus(new BigNumber(poolStakeAmount).times(sp).times(bnbPrice))
      }else {
        num1 = num1.plus(new BigNumber(poolStakeAmount).times(sp))
      }

    }else{
      const sp = new BigNumber(findPool.poolViewInfo.lpTotalInQuoteToken)
      if(findPool.stakedPairUsdtAddress.toLowerCase() === bnbAddress.toLowerCase()){
        num1 = num1.plus(sp.times(bnbPrice))
      }else {
        num1 = num1.plus(sp)
      }
    }
    if(!num1.isNaN()){
      setNum(new BigNumber(num1.toFixed(3)).toNumber())
    }

  }, [ setNum, farmsLP,bnbPrice,bnbAddress,pid])

  useEffect(() => {
    fetchPoolTvl()
  }, [fetchPoolTvl])
  return num
}
