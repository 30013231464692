import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import Collapse from '@material-ui/core/Collapse'
import { useSnackbar } from 'notistack'
import { useAppDispatch } from 'state'
import BigNumber from 'bignumber.js'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from 'state/actions'
import { editPool } from 'utils/callHelpers'
import { Farm } from 'state/types'
import { useLpContract, useStakingFactoryCreatorContract } from '../../../hooks/useContract'
import { getBlockByTimestamp } from '../../../hooks/useGetLastBlock'
import { BSC_BLOCK_TIME, DEFAULT_TOKEN_DECIMAL } from '../../../config'
import { getCheckPair } from '../../Home/hooks/helpers'
import { getBep20Contract, getLpContract } from '../../../utils/contractHelpers'
import { getTokenDecimals } from '../../../hooks/useHelpers'

const useEditPool = () => {
  // const dispatch = useAppDispatch()
  const { account ,chainId} = useWeb3React()
  const  stakingFactoryCreatorContract = useStakingFactoryCreatorContract()
  const { enqueueSnackbar } = useSnackbar();

  const handleEditPool = useCallback(async (info:{
    stakedTokenAddress: string
    stakedTokenName: string
    poolTypeV: number
    userMaxStakeAmount: string
    userMinStakeAmount: string
    maxStakeAmount: string
    lockDays: string
    feeValue: string
    rewardsTokenName: string
    rewardTotals: string
    rewardsTokenAddress: string
    startTime: number
    endTime: number
    officialSite: string
    twitterLink: string
    telegramLink: string
    feeAddress: string
    poolId: number
    rewardTokenUsdt: string
    stakeTokenUsdt: string
    stakeLogo: string
    rewardLogo: string
  },oldFarmData:Farm) => {

    // check the time
    if(info.endTime <= info.startTime){
      enqueueSnackbar('Pool start time is greater than end time', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return false
    }
    // check pair address
    const stakeTokenUsdtIsLP = await getCheckPair(getLpContract(info.stakeTokenUsdt))
    if( !stakeTokenUsdtIsLP){
      enqueueSnackbar('Staking Token Pair Address Is Not Pair', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return false
    }
    const rewardTokenUsdtIsLP = await getCheckPair(getLpContract(info.rewardTokenUsdt))
    if( !rewardTokenUsdtIsLP){
      enqueueSnackbar('Reward Token Pair Address Is Not Pair', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return false
    }
    // check user reward token balance
    const contract = getBep20Contract(info.rewardsTokenAddress)
    const reUB = await contract.balanceOf(account)
    const rewardTokenDecimals = await getTokenDecimals(info.rewardsTokenAddress)
    if(new BigNumber(reUB.toString()).lt(new BigNumber(info.rewardTotals).minus(new BigNumber(oldFarmData.rewardTotals)).times(new BigNumber(10).pow(rewardTokenDecimals)))){
      enqueueSnackbar('Insufficient Reward Token Balance', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return false
    }

    const stakeTokenDecimals = await getTokenDecimals(info.stakedTokenAddress)
    const startBlock = await getBlockByTimestamp(info.startTime)
    const rewardPerBlocks = new BigNumber(parseInt(info.rewardTotals) / ((info.endTime - info.startTime) / BSC_BLOCK_TIME/ 1000)).times(new BigNumber(10).pow(rewardTokenDecimals)).integerValue().toFixed()
    const rewardTotals = new BigNumber(info.rewardTotals).times(new BigNumber(10).pow(rewardTokenDecimals)).toFixed()
    const maxStakeAmount = new BigNumber(info.maxStakeAmount).times(new BigNumber(10).pow(stakeTokenDecimals)).toFixed()
    const lockSeconds = parseInt(info.lockDays) * 24 * 60 * 60
    const userMaxStakeAmount = new BigNumber(info.userMaxStakeAmount).times(new BigNumber(10).pow(stakeTokenDecimals)).toFixed()
    const userMinStakeAmount = new BigNumber(info.userMinStakeAmount).times(new BigNumber(10).pow(stakeTokenDecimals)).toFixed()

    const poolParams =  [];
    poolParams[0] = info.poolTypeV;
    poolParams[1] = startBlock;
    poolParams[2] = 1;
    poolParams[3] = maxStakeAmount;
    poolParams[4] = lockSeconds.toString();
    poolParams[5] = userMaxStakeAmount;
    poolParams[6] = userMinStakeAmount;
    poolParams[7] = new BigNumber(info.feeValue).toNumber();
    poolParams[8] = 0;
    poolParams[9] = 0;
    const poolViewParams = [];
    poolViewParams[0] = info.stakedTokenName;
    poolViewParams[1] = info.officialSite; //
    poolViewParams[2] = info.twitterLink; // twitter
    poolViewParams[3] = info.telegramLink; // telegram
    poolViewParams[4] = info.stakeLogo; // stake logo
    poolViewParams[5] = info.rewardLogo; // reward logo
    const pairsParams = [];
    pairsParams[0] = info.stakeTokenUsdt;
    pairsParams[1] = info.rewardTokenUsdt;

    try {
      const res = await editPool(
        stakingFactoryCreatorContract,
        info.poolId,
        info.stakedTokenAddress,
        poolParams,
        info.rewardsTokenAddress,
        rewardTotals,
        rewardPerBlocks,
        poolViewParams,
        info.feeAddress,
        pairsParams,
        account
      )
      return res
    }catch (e) {
      console.error(e)
      return false
    }

  }, [account,stakingFactoryCreatorContract,enqueueSnackbar])

  return { onEditPool: handleEditPool }
}

export default useEditPool
