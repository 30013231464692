import React from 'react'
import BigNumber from 'bignumber.js'
import { IconButton, useModal, CalculateIcon, ErrorIcon, useWalletModal } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'

import EditModal from './EditModal'
import { UiLuminousBtn } from '../../../components/UiLayout'
import useAuth from '../../../../hooks/useAuth'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { Farm } from '../../../../state/types'

interface props {
  farm: Farm
}
const EditPoolBtn: React.FC<props> = ({farm}) => {
  const { t } = useTranslation()
  const [onPresentEditModal] = useModal(
    <EditModal farm={farm}/>,
  )

  const handleClickButton = (event): void => {
    event.stopPropagation()
    onPresentEditModal()
  }
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <UiLuminousBtn onClick={account?handleClickButton:onPresentConnectModal} className="flex-1 mr-10">
      {t('Edit Pool')}
    </UiLuminousBtn>
  )
}

export default EditPoolBtn
