import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from "@web3-react/core";
import { getWeb3NoAccount } from 'utils/web3'
import { getAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { BIG_ZERO } from 'utils/bigNumber'
import useRefresh from 'hooks/useRefresh'
import { setBlock } from './actions'
import { State } from './types'
import useInterval from '../hooks/useInterval'
import { simpleRpcProvider } from '../utils/providers'
import useIsWindowVisible from '../hooks/useIsWindowVisible'

export const usePollBlockNumber = (refreshTime = 30000) => {
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()

  useInterval(
    () => {
      const fetchBlock = async () => {
        const blockNumber = await simpleRpcProvider.getBlockNumber()
        dispatch(setBlock(blockNumber))
      }

      fetchBlock()
    },
    isWindowVisible ? refreshTime : null,
    true,
  )
}

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const {chainId} = useWeb3React()
  // useEffect(() => {
  // }, [dispatch, slowRefresh, chainId])

  useEffect(() => {
    const web3 = getWeb3NoAccount()
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(setBlock(blockNumber))
    }, 6000)

    return () => clearInterval(interval)
  }, [dispatch,slowRefresh])
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
