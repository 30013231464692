import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { LinkExternal, useMatchBreakpoints } from 'trustfi-uikit'
import { useWeb3React } from "@web3-react/core";
import moment from 'moment'

import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'

import Apr, { AprProps } from '../Apr'
import { getExplorerUrl } from '../../../../../utils/formatUtil'
import Actionbtn from '../Actionbtn'

interface RowChildProps {
  apr: AprProps
  details: FarmWithStakedValue
  userDataReady: boolean
  expanded: boolean
}

const expandAnimation = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 500px;
  }
  to {
    max-height: 0px;
  }
`

const StyledChildBox = styled.div<{ expanded }>`
  position: relative;
  margin-top: 20px;
  color: ${({theme})=>theme.expandColor.color.white};
  padding: 0 15px;
  box-sizing: border-box;
  animation: ${({ expanded }) =>
  expanded
    ? css`
          ${expandAnimation} 300ms linear forwards
        `
    : css`
          ${collapseAnimation} 300ms linear forwards
        `};
`
const StyledInfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const StyledInfoItem = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 35%;
  }
`
const StyledInfoTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 24px;
  }
`
const StyledInfoContent = styled.div`
  font-size: 14px;
  margin-top : 5px;
  line-height: 1.4;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
  }
`
const StyledBtnRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: space-between;
    flex-direction: row;
  }
`
const StyledBtn = styled.div`
  position: relative;
  padding: 5px 0;
  box-sizing: border-box;
  & a{
    color: ${({theme})=>theme.expandColor.color.active};
    cursor: pointer;
  }
  & span {
    color: ${({theme})=>theme.expandColor.color.active};
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 10px;
  }
`
const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`

const RowChild: React.FunctionComponent<RowChildProps> = ({
  details,
  apr,
  userDataReady,
  expanded,
}) => {
  const farm = details
  const { chainId } = useWeb3React()
  const { t } = useTranslation()

  const explorerUrl = getExplorerUrl(chainId)
  const poolContractUrl = `${explorerUrl}/address/${farm.coreAddress}`

  const { endBlock,mayEndTime } = details
  const lockDay = farm.lockSeconds / 24/60/60
  const { isXl, isXs,isSm,isMd,isLg, } = useMatchBreakpoints()

  return (
    <StyledChildBox expanded={expanded}>
      { (isXs||isMd ||isSm||isLg) && (
        <StyledInfoRow>
          <Actionbtn details={details} userDataReady={userDataReady} />
        </StyledInfoRow>
      )}

      <StyledInfoRow>
        <StyledInfoItem>
          <StyledInfoTitle>Description</StyledInfoTitle>
          <StyledInfoContent>Each coin you add to the stake is automatically harvested</StyledInfoContent>
        </StyledInfoItem>
        <StyledInfoItem>
          <StyledInfoTitle>Unstaking Fee</StyledInfoTitle>
          <StyledInfoContent>Unstake within {lockDay} days will be charged {farm.feeValue}% fees. Timer resets every time you Unstake and stake</StyledInfoContent>
        </StyledInfoItem>
      </StyledInfoRow>
      <StyledBtnRow>
        <StyledLinkExternal href={poolContractUrl}>
          {t('Contact')}
        </StyledLinkExternal>
        <StyledBtn>
          {t('View Project Site: ')}
          {farm.poolViewInfo && farm.poolViewInfo.officialSite && (
            <a href={`${farm.poolViewInfo.officialSite.slice(0,4) === "http"?'':'https://'}${farm.poolViewInfo.officialSite}`} target="_blank" rel="noreferrer">
              {farm.poolViewInfo.officialSite}
            </a>
          )}
        </StyledBtn>
        <StyledBtn>
          {t('Pool Max Stake Amount: ')}
          <span>
          {farm.maxStakeAmount} {farm.stakeTokenSymbol}
        </span>
        </StyledBtn>
        <StyledBtn>
          {t('Rewards end in: ')}
          <span>
            {endBlock?moment(endBlock*1000).format("YYYY-MM-DD HH:mm:ss"):moment(mayEndTime).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        </StyledBtn>
      </StyledBtnRow>
    </StyledChildBox>
  )
}

export default RowChild
