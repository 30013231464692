import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from 'state/actions'
import { stake } from 'utils/callHelpers'
import { useStakingFactoryContract } from '../../../hooks/useContract'
import { useFarms } from '../../../state/farms/hooks'

const useStake = (factoryAddress: string, pid: number) => {
  const dispatch = useAppDispatch()
  const { account ,chainId} = useWeb3React()
  const stakingFactoryContract = useStakingFactoryContract(factoryAddress)
  const {data:farms} = useFarms()

  const handleStake = useCallback(
    async (amount: string,decimals: number) => {
      const txHash = await stake(stakingFactoryContract, pid, amount,decimals, account)
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchFarmUserDataAsync({account,farms}))
      return txHash
    },
    [account, dispatch, stakingFactoryContract, pid,farms],
  )

  return { onStake: handleStake }
}

export default useStake
