import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Button, useModal, IconButton, AddIcon, MinusIcon, Skeleton } from 'trustfi-uikit'
import { useLocation } from 'react-router-dom'
import UnlockButton from 'components/UnlockButton'
import { useFarmUser } from 'state/farms/hooks'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { useTranslation } from 'contexts/Localization'
import { BASE_ADD_LIQUIDITY_URL } from 'config'
import BigNumber from 'bignumber.js'

import { useApproveTokenToFactory } from '../../../hooks/useApprove'
import useStake from '../../../hooks/useStake'
import useUnstake from '../../../hooks/useUnstake'
import DepositModal from '../../DepositModal'
import WithdrawModal from '../../WithdrawModal'
import { ActionContainer2, ActionTitles, ActionContent, Earned, Title, Subtle, Staked as StyledStaked } from './styles'
import { UiStyledButton, UiStyledIconButton } from '../../../../components/UiLayout'
import useActiveWeb3React from '../../../../../hooks/useActiveWeb3React'

const IconButtonWrapper = styled.div`
  display: flex;
`

interface StackedActionProps  {
  userDataReady: boolean
  detail: FarmWithStakedValue
}

const Staked: React.FunctionComponent<StackedActionProps> = ({
  detail,
  userDataReady,
}) => {
  const { t } = useTranslation()
  const {
    pid,
    stakeTokenSymbol,
    stakeTokenAddress,
    factoryAddress,} = detail
  const { account, chainId } = useActiveWeb3React()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { balance, stakeAmount } = useFarmUser(pid)

  const tokenBalance = new BigNumber(balance)
  const stakedBalance = new BigNumber(stakeAmount)
  const lpAddress = stakeTokenAddress
  const { onStake } = useStake(factoryAddress,pid)
  const { onUnstake } = useUnstake(factoryAddress,pid)
  const location = useLocation()

  // checked Approve
  const [isApproved,setIsApproved] = useState(false)
  const {onApproveTokenToFactory,onAllowanceToken} = useApproveTokenToFactory(factoryAddress)
  useEffect(()=>{
    onAllowanceToken(lpAddress).then((res)=>{
      setIsApproved(res)
    })
  },[
    setIsApproved,onAllowanceToken,
    lpAddress,requestedApproval,
  ])

  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/`

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={stakeTokenSymbol} addLiquidityUrl={addLiquidityUrl} farm={detail} />,
  )
  const [onPresentWithdraw] = useModal(<WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={stakeTokenSymbol} stakeTokenDecimals={detail.stakeTokenDecimals} farm={detail}/>)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApproveTokenToFactory(lpAddress)
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApproveTokenToFactory,lpAddress])

  if (!account) {
    return (
      <ActionContainer2>
        <UnlockButton borderRadius="6px" />
      </ActionContainer2>
    )
  }

  if (isApproved) {
    return (
      <ActionContainer2>
        {stakedBalance.gt(0)?(
          <IconButtonWrapper>
            <UiStyledIconButton variant="secondary" onClick={onPresentWithdraw} mr="6px"  borderRadius="6px">
              <MinusIcon color="primary" width="14px" />
            </UiStyledIconButton>
            <UiStyledIconButton
              variant="secondary"
              onClick={onPresentDeposit}
              disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
              borderRadius="6px"
            >
              <AddIcon color="primary" width="14px" />
            </UiStyledIconButton>
          </IconButtonWrapper>
        ):(
          <UiStyledButton
            borderRadius="6px"
            onClick={onPresentDeposit}
            variant="secondary"
            disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
            width="max-content"
          >
            {t('Stake')}
          </UiStyledButton>
        )}

      </ActionContainer2>
    )
  }

  if (!userDataReady) {
    return (
      <ActionContainer2>
        <Skeleton marginBottom={28} marginTop={14} />
      </ActionContainer2>
    )
  }

  return (
    <ActionContainer2>
      <UiStyledButton width="max-content" disabled={requestedApproval} onClick={handleApprove} variant="secondary" borderRadius="6px">
        {t('Approve')}
      </UiStyledButton>
    </ActionContainer2>
  )
}

export default Staked
