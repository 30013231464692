import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Button, Flex, HelpIcon, useWalletModal } from 'trustfi-uikit'
import { walletSet } from 'components/Menu/config'
import BigNumber from 'bignumber.js'
import ReactTooltip from 'react-tooltip'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from '../../contexts/Localization'
import tokens from '../../config/constants/tokens'
import useTokenBalance, { useGetBnbBalance, useGetTFITokenPrice } from '../../hooks/useTokenBalance'
import { getAddress } from '../../utils/addressHelpers'
import { DEFAULT_TOKEN_DECIMAL } from '../../config'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import ChainLogo, { ChainNameLogo } from '../layout/ChainLogo'

interface LabelProps {
  text?: string
  isFinished?: boolean
}

const Wallet: React.FC<LabelProps> = ({ text, isFinished = false }) => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { login, logout, } = useAuth()
  // const tokenBalanceData = useTokenBalance(getAddress(tokens.int.address))
  const tokenBalanceData = useGetBnbBalance()
  const tokenBalance = new BigNumber(tokenBalanceData.balance).div(DEFAULT_TOKEN_DECIMAL).toNumber().toLocaleString('en-US', { maximumFractionDigits: 3 })

  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account, walletSet.helpLink, walletSet.scanLink, walletSet.scanLabel,tokenBalance,tokens.bnb.symbol);
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;
  const tfiPrice = useGetTFITokenPrice()
  return (
    <StyledContain>
      {account ? (
        <StyledFlex>
          <StyledBalanceBox>
            <StyledTokenIcon>
              <img alt="" src="/images/logo/logo3.png"/>
            </StyledTokenIcon>
            <StyledBalance>
              ${tfiPrice.toFixed(2)}
            </StyledBalance>
          </StyledBalanceBox>
          <StyledChainBox
            data-for="chain-change"
            data-tip
          >
            <ChainLogo/>
          </StyledChainBox>
          <StyledBtn
            onClick={() => {
              onPresentAccountModal();
            }}
          >
            {accountEllipsis}
          </StyledBtn>
        </StyledFlex>
      ) : (
        <StyledFlex>
          <StyledBalanceBox>
            <StyledTokenIcon>
              <img alt="" src="/images/logo/logo3.png"/>
            </StyledTokenIcon>
            <StyledBalance>
              ${tfiPrice.toFixed(2)}
            </StyledBalance>
          </StyledBalanceBox>
          <StyledChainBox
            data-for="chain-change"
            data-tip
          >
            <ChainLogo/>
          </StyledChainBox>
          <StyledBtn
            onClick={() => {
              onPresentConnectModal();
            }}
          >
            {t('Connect')}
          </StyledBtn>
        </StyledFlex>
      )}

      <StyledTooltip
        id="chain-change"
        className="custom-class"
        delayHide={500}
        effect="solid"
      >
        <ChainNameLogo cId={56} url="https://farmerbsc.trustfi.org"/>
        {/*
        <ChainNameLogo cId={1} url="https://farmereth.trustfi.org" />
        */}
        <ChainNameLogo cId={137} url="https://farmermatic.trustfi.org"/>
        <ChainNameLogo cId={43114} url="https://farmeravax.trustfi.org"/>

        <ChainNameLogo cId={128} url="https://farmerheco.trustfi.org"/>
        <ChainNameLogo cId={86} url="https://farmergate.trustfi.org"/>
        <ChainNameLogo cId={321} url="https://farmerkcc.trustfi.org"/>
        <ChainNameLogo cId={66} url="https://farmeroec.trustfi.org"/>
        <ChainNameLogo cId={250} url="https://farmerftm.trustfi.org"/>
      </StyledTooltip>
    </StyledContain>
  );
}

const StyledContain = styled.div`
  position: relative;
`
const StyledFlex = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
`
const StyledBtn = styled.button`
  height: 40px;
  padding: 0 24px;
  z-index: 2;
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  box-shadow: ${({theme})=>theme.expandColor.border.shadow1};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  ${({theme})=>theme.expandColor.color.active};
  color: ${({theme})=>theme.expandColor.color.pageBg};
  border: none;
  letter-spacing: 0.03em;
  line-height: 1;
  outline: 0;
  cursor: pointer;
  word-break: break-word;
`
const StyledChainBox = styled.div`
  height: 32px;
  padding: 0 20px 0 10px;
  margin-right: -10px;
  z-index: 2;
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  ${({theme})=>theme.expandColor.color.color1};
  color: ${({theme})=>theme.expandColor.color.pageBg};
  border: none;
  letter-spacing: 0.03em;
  line-height: 1;
  outline: 0;
  cursor: pointer;
  word-break: break-word;
  & img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`
const StyledBalanceBox = styled.div`
  display: none;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  }
`
const StyledTokenIcon = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  margin-right: 5px;
  padding: 3px;
  box-sizing: border-box;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const StyledBalance = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({theme})=>theme.expandColor.color.active};
  text-shadow: 0 0 6px ${({theme})=>theme.expandColor.color.active};
`

const StyledTooltip = styled(ReactTooltip)`
  pointer-events: auto !important;
  opacity: 1 !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  &.custom-class{
    color: ${({theme})=>theme.expandColor.color.black};
    border: 1px solid ${({theme})=>theme.expandColor.color.active};
    background-color: ${({theme})=>theme.expandColor.color.black};
    box-shadow: none;
    max-width: 250px;
  }
  &.custom-class.place-bottom:after{
    border-bottom-color:  ${({theme})=>theme.expandColor.color.active};
  }
  &.custom-class.place-top:after{
    border-top-color:  ${({theme})=>theme.expandColor.color.active};
  }
  &.custom-class.place-left:after{
    border-left-color:  ${({theme})=>theme.expandColor.color.active};
  }
  &.custom-class.place-right:after{
    border-right-color:  ${({theme})=>theme.expandColor.color.active};
  }
  & > * {
    margin: 5px 0;
  }
`
export default Wallet
