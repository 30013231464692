import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from 'state/actions'
import { unstake } from 'utils/callHelpers'
import { useMasterchef, useStakingFactoryContract } from '../../../hooks/useContract'
import { useFarms } from '../../../state/farms/hooks'

const useUnstake = (factoryAddress: string, pid: number) => {
  const dispatch = useAppDispatch()
  const { account,chainId } = useWeb3React()
  const stakingFactoryContract = useStakingFactoryContract(factoryAddress)
  const {data:farms} = useFarms()
  const handleUnstake = useCallback(
    async (amount: string,decimals: number) => {
      const txHash = await unstake(stakingFactoryContract, pid, amount,decimals, account)
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchFarmUserDataAsync({account,farms}))
      return txHash
    },
    [account, dispatch, stakingFactoryContract, pid,farms],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstake
