import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { useFarms } from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import { PoolSpaceData } from 'state/types'
import { UiContainer } from '../../../components/layout/StyledUi'
import SearchInput from './SearchInput'
import SpaceItem from './SpaceItem'
import ApplyForSpaceBtn from './ApplyForSpaceBtn'

const SpaceLayout:React.FC = ()=>{
  const { t } = useTranslation()
  const { spaceList } = useFarms()
  const [query, setQuery] = useState('')

  // search space by name
  const spaceQueryFind = useCallback(
    (spacesToDisplay: PoolSpaceData[]): PoolSpaceData[] => {
      let spaceFilter = spacesToDisplay
      if (query) {
        const lowercaseQuery = latinise(query.toLowerCase())
        spaceFilter = spaceFilter.filter((space: PoolSpaceData) => {
          return latinise(space.spaceName.toLowerCase()).includes(lowercaseQuery)
        })
      }
      return spaceFilter
    },
    [ query],
  )
  const spacesListMemoized = useMemo(() => {
    const spacesFind = spaceQueryFind(spaceList)
    return spacesFind
  }, [
    spaceList,
    spaceQueryFind,
  ])

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }


  return (
    <>
      <StyledBtnRow>
        <SearchInput onChange={handleChangeQuery} />
        <ApplyForSpaceBtn/>
      </StyledBtnRow>
      <StyledSpaceRow>
        {spacesListMemoized.map((item) => (
          <SpaceItem key={item.ownerAddress} spaceDetail={item} />
        ))}
      </StyledSpaceRow>
      {spacesListMemoized.length===0 &&  (
        <StyledNoResult>{t('No Result')}</StyledNoResult>
      )}
    </>
  )
}

const StyledBtnRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`
const StyledSpaceRow  = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const StyledNoResult = styled.div`
  text-align: center;
  line-height: 2;
  font-size: 28px;
  color: ${({theme})=>theme.expandColor.color.white};
`

export default SpaceLayout
