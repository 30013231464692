import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { ArrowDropDownIcon, Text } from 'trustfi-uikit'
import usePersistState from '../../../../hooks/usePersistState'
import { CreateInputDefault } from '../types'

const DropDownHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border: 2px solid ${({ theme }) => theme.expandColor.color.active};
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  background: ${({ theme }) => theme.expandColor.color.pageBg};
  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div`
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.expandColor.color.pageBg};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;

`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number }>`
  cursor: pointer;
    // width: ${({ width }) => width}px;
  width: 100%;
  flex: 1;
  position: relative;
  background: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  height: 40px;

  ${(props) =>
          props.isOpen &&
          css`
            ${DropDownHeader} {
              border-top: 1px solid ${({ theme }) => theme.expandColor.color.active};
              box-shadow: ${({ theme }) => theme.tooltip.boxShadow};
              border-radius: 0 0 6px 6px;
            }

            ${DropDownListContainer} {
              height: auto;
              transform: scaleY(1);
              opacity: 1;
              border: 2px solid ${({ theme }) => theme.expandColor.color.active};
              border-bottom-width: 0;
              border-radius: 6px 6px 0 0;
              box-shadow: ${({ theme }) => theme.tooltip.boxShadow};
            }
          `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => theme.expandColor.color.active};
    & ${Text} {
      color: ${({ theme }) => theme.expandColor.color.black} !important;
    }
  }
`

interface SelectProps {
  options: PaymentSelectOptionProps[]
  onChange?: (option: PaymentSelectOptionProps) => void
}

export interface PaymentSelectOptionProps {
  label: string
  symbol: string
  address: string
  poolAmount: string
  userBalance: number
}

const PaymentSelect: React.FunctionComponent<SelectProps> = ({ options, onChange }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  // Get browser cached input data
  const {account} = useWeb3React()
  const [localData, setLocalData] = usePersistState(CreateInputDefault, `tfi-create-user-${account}`)
  useEffect(()=>{
    const findPaymentOption = options.find((item)=>item.address.toLowerCase()===localData.commissionTokenAddress.toLowerCase())
    setSelectedOption(findPaymentOption??options[0])
  },[options,setSelectedOption,localData])

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (option: PaymentSelectOptionProps) => () => {
    setSelectedOption(option)
    setIsOpen(false)

    if (onChange) {
      onChange(option)
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })
  }, [])

  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize}>
      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option) =>{
            return (
              <ListItem onClick={onOptionClicked(option)} key={option.address}>
                <Text color="primary">{option?.label}</Text>
              </ListItem>
            )
          })}
        </DropDownList>
      </DropDownListContainer>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling}>
          <Text color="primary">{selectedOption?.label}</Text>
        </DropDownHeader>
      )}
      <ArrowDropDownIcon color="text" onClick={toggling} />

    </DropDownContainer>
  )
}

export default PaymentSelect
