import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'trustfi-uikit/dist/theme'

declare module 'styled-components' {
  export interface DefaultTheme extends PancakeTheme {
    expandColor: any
    css: any
  }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Roboto, 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.expandColor.color.pageBg};

    img {
      height: auto;
      max-width: 100%;
    }
    
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  
  .swiper-pagination-bullet {
    background: #ccc;
    opacity: 0.2;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${({theme})=>theme.expandColor.color.active};
    position: relative;
  }
  .swiper-button-prev, .swiper-button-next {
    color: ${({theme})=>theme.expandColor.color.active};
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
    left: 0;
    width: 100%;
}
`

export default GlobalStyle
