import React from 'react'
import styled from 'styled-components'
import { Tag, Flex, Heading, Image, useMatchBreakpoints, HelpIcon } from 'trustfi-uikit'
import {ReportProblem} from "@material-ui/icons"
import ReactTooltip from 'react-tooltip'

import LpFarmIcon from "../LpFarmIcon/LpFarmIcon";

export interface ExpandableSectionProps {
  lpLabel?: string
  multiplier?: string
  isCommunityFarm?: boolean
  farmImage?: string
  tokenSymbol?: string
  earnLabel?: string
  poolName?: string
  isErrorPool?: boolean
  rewardTokenImg?: string
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`

const SubTitle = styled.div`
  font-size: 16px;
  color: ${({theme})=>theme.expandColor.color.white};
  text-align: left;
  & span {
    font-weight: 600;
  }
`

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  multiplier,
  isCommunityFarm,
  farmImage,
  tokenSymbol,
  earnLabel,
  poolName,
  isErrorPool,
  rewardTokenImg,
}) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <Wrapper justifyContent="flex-start" alignItems="center" mb="12px">
      <LpFarmIcon imgUrl={farmImage} size={isMobile?40:65} subImg={rewardTokenImg} subSize={isMobile?20:30}/>
      <Flex flexDirection="column" alignItems="flex-start">
        <Heading color="white" mb="4px">
          {isErrorPool && (
            <StyledErrorIcon
              data-for="error-pool"
              data-tip
            >
              <ReportProblem style={{ fontSize: 16 }}/>
            </StyledErrorIcon>
          )}

          {poolName}
        </Heading>
        <SubTitle>Stake {lpLabel} - Earn {earnLabel}</SubTitle>
      </Flex>
      <StyledTooltip
        id="error-pool"
        className="custom-class"
        delayHide={500}
        effect="solid"
      >
        error pool
      </StyledTooltip>
    </Wrapper>
  )
}

const StyledErrorIcon = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  & svg{
    fill: red;
  }
`

const StyledTooltip = styled(ReactTooltip)`
 pointer-events: auto !important;
 opacity: 1 !important;
 &:hover {
  visibility: visible !important;
  opacity: 1 !important;
 }
 &.custom-class{
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
  box-shadow: none;
  max-width: 250px;
  word-break: break-all;
 }
 &.custom-class.place-bottom:after{
  border-bottom-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-top:after{
  border-top-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-left:after{
  border-left-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-right:after{
  border-right-color:  ${({theme})=>theme.expandColor.color.active};
 }
`
export default CardHeading
