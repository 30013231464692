import React from 'react'
import { useFarms, useGetSpaceAdds } from 'state/farms/hooks'
import { useTranslation } from 'contexts/Localization'
import FarmsContent from './FarmsContent'

// Featured Pools
const FeaturedPools: React.FC = () => {
  const { t } = useTranslation()
  const { data: farmsLPList} = useFarms()
  const {spaceAdds} = useGetSpaceAdds()
  const spacePools = farmsLPList.filter((item)=>{
    return spaceAdds.includes(item.poolOwner.toLowerCase())
  })

  return (
    <FarmsContent farmsLPList={spacePools} viewModeKey="tfi_featured_view" farmHeadTitle="Featured Pools" createBtnType="Feature"/>
  )
}

export default FeaturedPools
