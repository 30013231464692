import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import Collapse from '@material-ui/core/Collapse'
import { Button, Flex, Heading, Text } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { useWeb3React } from '@web3-react/core'
import { useHarvest } from '../../hooks/useHarvest'

interface FarmCardActionsProps {
  earnings?: BigNumber
  pid?: number
  earnSymbol?: string
  factoryAddress: string
  earnTokenDecimals: number
}

const HarvestAction: React.FC<FarmCardActionsProps> = ({ factoryAddress,earnings, pid,earnSymbol,earnTokenDecimals }) => {
  const { account,chainId } = useWeb3React()
  const { t } = useTranslation()
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useHarvest(factoryAddress,pid)

  const rawEarningsBalance = account ? getBalanceNumber(earnings,earnTokenDecimals) : 0
  const displayBalance = rawEarningsBalance.toLocaleString(undefined, { maximumFractionDigits: 5 })
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Flex mb="20px" justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Text bold color="white" fontSize="16px" pr="3px" textAlign="left">
          {earnSymbol} {t('Earned')}
        </Text>
        <Heading textAlign="left" color='primary' style={{wordBreak: 'break-all'}}>
          {displayBalance}
        </Heading>
      </Flex>

      <StyledBtn
        disabled={rawEarningsBalance === 0 || pendingTx}
        onClick={async () => {
          setPendingTx(true)
          const res= await onReward()
          if(res){
            enqueueSnackbar('Harvest Successfully', {
              variant: 'success' ,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              autoHideDuration: 2500,
              TransitionComponent: Collapse,
            });
          }
          setPendingTx(false)
        }}
      >
        {t('Harvest')}
      </StyledBtn>
    </Flex>
  )
}

const StyledBtn = styled(Button)`
  width: 110px;
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  color: ${({theme})=>theme.expandColor.color.black};
`
export default HarvestAction
