import React from 'react'
import styled from 'styled-components'

const Label = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.expandColor.color.white};
`

const ContentContainer = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
`
const StyledDiv = styled.div<{flex?: number}>`
  flex: ${({flex})=>flex??'none'};
  box-sizing: border-box;
  padding-left: 5px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 0;
  }
`

interface CellLayoutProps {
  label?: string
  flex?: number
}

const CellLayout: React.FC<CellLayoutProps> = ({ label = '', children,flex }) => {
  return (
    <StyledDiv flex={flex}>
      <ContentContainer>{children}</ContentContainer>
      {label && <Label>{label}</Label>}
    </StyledDiv>
  )
}

export default CellLayout
