import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { NFTStorage, File } from 'nft.storage'

export const approve = async (lpContract, masterChefContract, account) => {
  const tx = await lpContract.approve(masterChefContract.address, ethers.constants.MaxUint256)
  const receipt = await tx.wait()
  return receipt.status
}

export const stake = async (masterChefContract, pid, amount,decimals, account) => {
  try{
    const tx = await masterChefContract.stake(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toFixed(),
    )
    const receipt = await tx.wait()
    return receipt.status
  }catch (e) {
    console.error('stake error',e)
    return false
  }
}

export const unstake = async (masterChefContract, pid, amount,decimals, account) => {
  const tx = await masterChefContract.unStake(
    pid,
    new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toFixed(),
  )
  const receipt = await tx.wait()
  return receipt.status
}

export const harvest = async (masterChefContract, pid, account) => {
  const tx = await masterChefContract.withdrawReward(
    pid,
  )
  const receipt = await tx.wait()
  return receipt.status
}

export const createPool = async (
  stakingFactoryCreatorContract,
  token,
  commissionToken,
  poolParams,
  tokens,
  rewardTotals,
  rewardPerBlocks,
  poolViewParams,
  feeAddress,
  pairs,
  account
) => {
  const tx = await stakingFactoryCreatorContract.createPool(
    token,
    commissionToken,
    poolParams,
    tokens,
    rewardTotals,
    rewardPerBlocks,
    poolViewParams,
    feeAddress,
    pairs,
  )
  const receipt = await tx.wait()
  return receipt.status

}
export const editPool = async (
  stakingFactoryCreatorContract,
  poolId,
  token,
  poolParams,
  tokens,
  rewardTotals,
  rewardPerBlocks,
  poolViewParams,
  feeAddress,
  pairsParams,
  account
) => {
  const tx = await stakingFactoryCreatorContract.editPool(
    poolId,
    token,
    poolParams,
    tokens,
    rewardTotals,
    rewardPerBlocks,
    poolViewParams,
    feeAddress,
    pairsParams,
  )
  const receipt = await tx.wait()
  return receipt.status

}

export const closePool = async (
  stakingFactoryCreatorContract,
  poolId,
  account
) => {
  const tx = await stakingFactoryCreatorContract.closePool(
    poolId,
  )
  const receipt = await tx.wait()
  return receipt.status
}

