import { useCallback, useEffect, useState } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { simpleRpcProvider } from '../utils/providers'

export const useGetLastBlock = ()=>{
  const { chainId, account } = useActiveWeb3React()
  const [blockTimestamp,setBlockTimestamp] = useState(0)

  useEffect(() => {
    const fetchBlock = async () => {
      const blockNumber = await simpleRpcProvider.getBlockNumber()
      const blockDetail = await simpleRpcProvider.getBlock(blockNumber)
      // console.log('blockDetail.timestamp', blockDetail.timestamp)
      // console.log('simpleRpcProvider._fastQueryDate', simpleRpcProvider._fastQueryDate)
      // blockDetail.timestamp 为11位时间戳，单位为秒
      // simpleRpcProvider._fastQueryDate 单位为毫秒
      setBlockTimestamp(blockDetail.timestamp)
    }
    if(chainId){
      fetchBlock()
    }
  }, [chainId, account,setBlockTimestamp])
  return blockTimestamp
}

export const getBlockByTimestamp = async(timestamp: number)=>{
  const blockNumber = await simpleRpcProvider.getBlockNumber()
  // const blockDetail = await simpleRpcProvider.getBlock(blockNumber)
  const lastBlockTime = await simpleRpcProvider._fastQueryDate
  // const curBlockTime =  blockDetail.timestamp
  const minusTime = timestamp - lastBlockTime
  const minusBlock = Math.round(minusTime / 1000 / 3)
  const newBlockNumber = minusBlock + blockNumber
  //
  // console.log('timestamp', timestamp)
  // console.log('lastBlockTime', lastBlockTime)
  // console.log('minusBlock', minusBlock)
  // console.log('blockNumber', blockNumber)
  // console.log('newBlockNumber', newBlockNumber)

  return newBlockNumber
}

export const getBlockTimestamp = async(block:number)=>{
  const curBlockNumber = await simpleRpcProvider.getBlockNumber()
  const curBlockTime = await simpleRpcProvider._fastQueryDate
  if(block>curBlockNumber){
    const minusTime = (block - curBlockNumber) * 3000
    return (minusTime + curBlockTime)
  }
  const blockDetail = await simpleRpcProvider.getBlock(block)
  return blockDetail.timestamp * 1000
}

export const useGetBlockTimestampCallBack = (block:number)=>{
  const [time,setTime] = useState(0)
  const handleGetTime = useCallback(async() => {
    const curBlockNumber = await simpleRpcProvider.getBlockNumber()
    const curBlockTime = await simpleRpcProvider._fastQueryDate
    if(block>curBlockNumber){
      const minusTime = (block - curBlockNumber) * 3000
      setTime( minusTime + curBlockTime)
    }else{
      const blockDetail = await simpleRpcProvider.getBlock(block)
      setTime(blockDetail.timestamp * 1000)
    }
  }, [block,setTime])

  useEffect(()=>{
    handleGetTime()
  },[handleGetTime])
  return time
}

export default null
