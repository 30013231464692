import { useCallback } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { useSnackbar } from 'notistack'
import { uploadNftStorage } from './helpers'

const useUploadImg = () => {
  const { enqueueSnackbar } = useSnackbar();
  const authorization = `Bearer ${process.env.REACT_APP_NFT_STORAGE_API_KEY}`

  const handleUploadImg = useCallback(async (imgFileData: any) => {

    try {
      const res = await uploadNftStorage(
        authorization,
        imgFileData,
        'image/*',
      )
      if(!res){
        console.error(res)
        enqueueSnackbar('Upload Failed', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 3000,
          TransitionComponent: Collapse,
        });
        return false
      }
      return res
    }catch (e) {
      console.error(e)
      enqueueSnackbar('Upload Failed', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 3000,
        TransitionComponent: Collapse,
      });
      return false
    }

  }, [enqueueSnackbar,authorization])

  return { onUploadImg: handleUploadImg }
}

export default useUploadImg
