import React from 'react'
import styled from 'styled-components'
import { Skeleton } from 'trustfi-uikit'

export interface StakedProps {
  staking: number
  pid: number
}

interface EarnedPropsWithLoading extends StakedProps {
  userDataReady: boolean
}

const Amount = styled.span<{ earned: number }>`
  // color: ${({ earned, theme }) => (earned ? theme.colors.text : theme.colors.textDisabled)};
  color: ${({theme})=>theme.expandColor.color.active};
  display: flex;
  align-items: center;
`

const Staked: React.FunctionComponent<EarnedPropsWithLoading> = ({ staking, userDataReady }) => {
  if (userDataReady) {
    return <Amount earned={staking}>{staking.toLocaleString()}</Amount>
  }
  return (
    <Amount earned={0}>
      <Skeleton width={60} />
    </Amount>
  )
}

export default Staked
