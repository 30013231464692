import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'TFI staking',
  description:
    'TFI staking.',
  image: 'http://stakingtools.trustfi.org/logo2.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('TFI staking')}`,
      }
    default:
      return null
  }
}
