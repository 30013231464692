import { HelpIcon } from 'trustfi-uikit'
import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

interface props {
  label: string
  tooltipId: string
  tipsText: string
}
interface props2 {
  label: string
}
const InputLabel: React.FC<props> = ({label,tooltipId,tipsText})=>{

  return (
    <StyledInputLabel>
      {label}
      <HelpIcon color="textSubtle"
                data-for={tooltipId}
                data-tip
      />
      <StyledTooltip
        id={tooltipId}
        className="custom-class"
        delayHide={500}
        effect="solid"
      >
        {tipsText}
      </StyledTooltip>
    </StyledInputLabel>
  )
}
export const InputLabelNoTip: React.FC<props2> = ({label})=>{
  return (
    <StyledInputLabel>
      {label}
    </StyledInputLabel>
  )
}
const StyledInputLabel = styled.div`
  flex: 1;
  color: ${({theme})=>theme.expandColor.color.white};
  position: relative;
  & svg {
    vertical-align: middle;
    margin-left: 4px;
    cursor: pointer;
  }
`

const StyledTooltip = styled(ReactTooltip)`
 pointer-events: auto !important;
 opacity: 1 !important;
 &:hover {
  visibility: visible !important;
  opacity: 1 !important;
 }
 &.custom-class{
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
  box-shadow: none;
  max-width: 250px;
  word-break: break-all;
 }
 &.custom-class.place-bottom:after{
  border-bottom-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-top:after{
  border-top-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-left:after{
  border-left-color:  ${({theme})=>theme.expandColor.color.active};
 }
 &.custom-class.place-right:after{
  border-right-color:  ${({theme})=>theme.expandColor.color.active};
 }
`
export default InputLabel
