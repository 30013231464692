import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation, Link, useRouteMatch } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem, NotificationDot, useModal } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'
import FinishPoolTipModal from './FinishPoolTipModal'

interface FarmTabButtonsProps {
  hasStakeInFinishedFarms: boolean
  onTab: (option:string)=>void
  tabSelect: string
}

const FarmTabButtons: React.FC<FarmTabButtonsProps> = ({ hasStakeInFinishedFarms,onTab,tabSelect }) => {
  const { url } = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation()
  const [type,setType] = useState(tabSelect)

  const [onPresentFinishPoolTipModal] = useModal(
    <FinishPoolTipModal />,
  )
  const onChangeTab = (option: string)=>()=>{
    setType(option)
    onTab(option)
    if(option==='history'){
      onPresentFinishPoolTipModal()
    }
  }

  return (
    <Wrapper>
      <BtnRow>
        <BtnItem
          className={type==='active'?'active':""}
          onClick={onChangeTab('active')}
        >
          {t('Live')}
        </BtnItem>
        <BtnItem
          className={type==='history'?'active':""}
          onClick={onChangeTab('history')}
        >
          {t('Finished')}
        </BtnItem>
      </BtnRow>
    </Wrapper>
  )
}

export default FarmTabButtons

interface StakeTabButtonsProps {
  stakedOnly: boolean
  onChecked: (option:boolean)=>void
}
export const StakeTabButtons: React.FC<StakeTabButtonsProps> = ({ stakedOnly,onChecked }) => {
  const { url } = useRouteMatch()
  const { t } = useTranslation()
  const [type,setType] = useState(stakedOnly)

  const onChangeTab = (option: boolean)=>()=>{
    setType(option)
    onChecked(option)
  }

  return (
    <Wrapper>
      <BtnRow>
        <BtnItem
          className={!type?'active':""}
          onClick={onChangeTab(false)}
        >
          {t('All')}
        </BtnItem>
        <BtnItem
          className={type?'active':""}
          onClick={onChangeTab(true)}
        >
          {t('Staked')}
        </BtnItem>
      </BtnRow>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

`
const BtnRow = styled.div`
  background-color: ${({theme})=>theme.expandColor.color.pageBg};
  border-radius: ${({theme})=>theme.expandColor.radius.radius2};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  display: inline-flex;
  word-spacing: -1px;
  box-sizing: border-box;
`
const BtnItem = styled.div`
  height: 32px;
  padding: 0 10px;
  color: ${({theme})=>theme.expandColor.color.white};
  border-radius: ${({theme})=>theme.expandColor.radius.radius2};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1px;
  &.active {
      background-color: ${({theme})=>theme.expandColor.color.active};
      color: ${({theme})=>theme.expandColor.color.black};
  }
  font-size: 12px;
 ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 20px;
    height: 38px;
    font-size: 14px;
  }
`
