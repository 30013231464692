import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'

import { ActionContainer2 } from './styles'
import { UiStyledButton } from '../../../../components/UiLayout'
import { useHarvest } from '../../../hooks/useHarvest'
import { useFarmUser } from '../../../../../state/farms/hooks'

interface HarvestActionProps extends FarmWithStakedValue {
  userDataReady: boolean
}

const HarvestAction: React.FunctionComponent<HarvestActionProps> =
  ({
     pid,
     userDataReady,
    factoryAddress,
  }) => {
  const userPoolData = useFarmUser(pid)
  const earningsBigNumber = userPoolData.reward
  let earnings = 0

  // If user didn't connect wallet default balance will be 0
  if (!earningsBigNumber.isZero()) {
    earnings = getBalanceNumber(earningsBigNumber)
  }

  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useHarvest(factoryAddress,pid)
  const { t } = useTranslation()

  return (
    <ActionContainer2 className="mr">
      <StyledUiStyledButton
        disabled={!earnings || pendingTx || !userDataReady}
        onClick={async () => {
          setPendingTx(true)
          await onReward()
          setPendingTx(false)
        }}
        style={{boxShadow: 'none'}}
        borderRadius="6px"
      >
        {t('Harvest')}
      </StyledUiStyledButton>
    </ActionContainer2>
  )
}

const StyledUiStyledButton = styled(UiStyledButton)`
  color: ${({theme})=>theme.expandColor.color.black};
`

export default HarvestAction
