import BigNumber from 'bignumber.js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'trustfi-uikit'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { useTranslation } from 'contexts/Localization'
import { getFullDisplayBalance } from 'utils/formatBalance'
import styled from 'styled-components'
import { Farm } from '../../../state/types'
import { useFarmUser } from '../../../state/farms/hooks'

interface WithdrawModalProps {
  max: BigNumber
  onConfirm: (amount: string,decimals: number) => void
  onDismiss?: () => void
  tokenName?: string
  stakeTokenDecimals?:number
  farm: Farm
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ onConfirm, onDismiss, max, tokenName = '',stakeTokenDecimals,farm }) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max,stakeTokenDecimals)
  }, [max,stakeTokenDecimals])

  const valNumber = new BigNumber(val)
  const fullBalanceNumber = new BigNumber(fullBalance)

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setVal],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  const userFarmData = useFarmUser(farm.pid)
  //  user unstake need fee?
  const [isNeedFee,setIsNeedFee] = useState(false)
  useEffect(()=>{
    const userLockSeconds = (new Date().getTime() - userFarmData.lastStakeTime)
    if(userLockSeconds < (farm.lockSeconds*1000)) {
      setIsNeedFee(true)
    }

  },[userFarmData,farm,setIsNeedFee])


  return (
    <Modal title={`Untake ${tokenName}`} onDismiss={onDismiss}>
      <ModalInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
        inputTitle={t('Unstake')}
      />
      <StyledUnstakeFee>
        Unstake Fee: {isNeedFee?farm.feeValue:0}%
      </StyledUnstakeFee>
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%" disabled={pendingTx}>
          {t('Cancel')}
        </Button>
        <StyledBtn
          disabled={pendingTx || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(fullBalanceNumber)}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val,stakeTokenDecimals)
            setPendingTx(false)
            onDismiss()
          }}
          width="100%"
        >
          {pendingTx ? t('Pending Confirmation') : t('Confirm')}
        </StyledBtn>
      </ModalActions>
    </Modal>
  )
}

const StyledBtn = styled(Button)`
  color: ${({theme})=>theme.expandColor.color.black};
  background-color: ${({theme})=>theme.expandColor.color.active};
`
const StyledUnstakeFee = styled.div`
  font-size: 14px;
  color:  ${({theme})=>theme.expandColor.color.white};
  line-height: 2;
`
export default WithdrawModal
