import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { provider as ProviderType } from 'web3-core'
import BigNumber from 'bignumber.js'
import { Button, Flex, Text } from 'trustfi-uikit'
import { useWeb3React } from "@web3-react/core";

import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import UnlockButton from 'components/UnlockButton'
import StakeAction from './StakeAction'
import HarvestAction from './HarvestAction'
import useBlockCountdown from '../../../../hooks/useGetBlockCountdown'
import { formatRoundTime } from '../../../components/helper'
import { useApproveTokenToFactory } from '../../hooks/useApprove'
import { useFarmUser } from '../../../../state/farms/hooks'

const Action = styled.div`
  padding-top: 16px;
  flex: 2;
`

const ComeSoon = styled.div`
  font-size: 20px;
  width: 100%;
  color: ${({theme})=>theme.colors.text};
  font-weight: 400;
  text-align: center;
`
const StyledCountdown = styled.div`
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${({theme})=>theme.colors.text};
`
const StyledBtn = styled(Button)`
  border-radius: ${({theme})=>theme.expandColor.radius.radius1};
  color: ${({theme})=>theme.expandColor.color.black};
`
export interface FarmWithStakedValue extends Farm {
  apr?: number
}

interface FarmCardActionsProps {
  farm: FarmWithStakedValue
  provider?: ProviderType
  account?: string
  addLiquidityUrl?: string
}

const CardActions: React.FC<FarmCardActionsProps> = ({ farm, account, addLiquidityUrl }) => {
  const { t } = useTranslation()
  const { chainId } = useWeb3React()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { pid ,stakeTokenAddress, startBlock} = farm
  const userFarmData = useFarmUser(pid)
  const tokenBalance = new BigNumber(userFarmData.balance)
  const stakedBalance = new BigNumber(userFarmData.stakeAmount)
  const earnings = new BigNumber(userFarmData.reward)
  const lpAddress = stakeTokenAddress
  const lpName = farm.stakeTokenSymbol.toUpperCase()

  const [isApproved,setIsApproved] = useState(false)
  const {onApproveTokenToFactory,onAllowanceToken} = useApproveTokenToFactory(farm.factoryAddress)
  useEffect(()=>{
    setIsApproved(new BigNumber(userFarmData.allowance).isGreaterThan(0))
  },[userFarmData, setIsApproved])

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const res = await onApproveTokenToFactory(lpAddress)
      if(res){
        setIsApproved(true)
      }
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApproveTokenToFactory,lpAddress,setIsApproved])

  const renderApprovalOrStakeButton = () => {
    return isApproved ? (
      <StakeAction
        stakedBalance={stakedBalance}
        tokenBalance={tokenBalance}
        tokenName={lpName}
        pid={pid}
        addLiquidityUrl={addLiquidityUrl}
        stakeSymbol={lpName}
        factoryAddress={farm.factoryAddress}
        farm={farm}
        stakeTokenDecimals={farm.stakeTokenDecimals}
      />
    ) : (
      <StyledBtn width="100%" disabled={requestedApproval} onClick={handleApprove}>
        {t('Approve')}
      </StyledBtn>
    )
  }

  const secondsRemaining = useBlockCountdown(startBlock)
  const countdown = formatRoundTime(secondsRemaining)

  return farm.endBlock===0 && secondsRemaining>0?(
    <Action>
      <ComeSoon>
        {t('Start')}
      </ComeSoon>
      <StyledCountdown>{countdown}</StyledCountdown>
    </Action>
  ):(
    <Action>
      <HarvestAction factoryAddress={farm.factoryAddress} earnSymbol={farm.rewardTokenSymbol} earnings={earnings} pid={pid} earnTokenDecimals={farm.rewardTokenDecimals}/>

      {!account ? <UnlockButton mt="8px" width="100%" /> : renderApprovalOrStakeButton()}
    </Action>
  )
}

export default CardActions
