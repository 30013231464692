import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useSnackbar } from 'notistack'
import { closePool } from 'utils/callHelpers'
import { useStakingFactoryCreatorContract } from '../../../hooks/useContract'

const useClosePool = () => {
  const { account } = useWeb3React()
  const  stakingFactoryCreatorContract = useStakingFactoryCreatorContract()
  const { enqueueSnackbar } = useSnackbar();

  const handleClosePool = useCallback(async (poolId: number) => {

    try {
      const res = await closePool(
        stakingFactoryCreatorContract,
        poolId,
        account
      )
      return res
    }catch (e) {
      console.error(e)
      return false
    }

  }, [account,stakingFactoryCreatorContract])

  return { onClosePool: handleClosePool }
}

export default useClosePool
