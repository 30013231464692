import BigNumber from 'bignumber.js'
import erc20 from 'vipswap/lib/abi/erc20.json'
import lpTokenAbi from 'vipswap/lib/abi/uni_v2_lp.json'
import factoryCreatorABI from 'vipswap/lib/abi/TrustFiStakingFactoryCreator.json'
import factoryABI from 'vipswap/lib/abi/TrustFiStakingFactory.json'
import factoryCoreABI from 'vipswap/lib/abi/TrustFiStakingFactoryCore.json'
import PAIRAbi from 'vipswap/lib/abi/pair.json'
import multicall from 'utils/multicall'
import { simpleRpcProvider } from 'utils/providers'
import { getBasePriceAddressArr, getFactoryCreatorAddress } from 'utils/addressHelpers'
import { BLOCKS_PER_YEAR, DEFAULT_TOKEN_DECIMAL } from 'config'
import { Farm } from '../types'
import { getBlockTimestamp } from '../../hooks/useGetLastBlock'
import { getCertificationPools } from './helpers'
import { stake } from '../../utils/callHelpers'
import { BIG_ZERO } from '../../utils/bigNumber'

export const fetchFactoryPools = async ()=>{
  const factoryCreatorAddress = getFactoryCreatorAddress()
  const certificationPools = await getCertificationPools()
  const curChainName = process.env.REACT_APP_CHAIN_NAME
  const curBlockNumber = await simpleRpcProvider.getBlockNumber()
  // get usdt/bnb/busd address array
  const basePriceAddressArr = getBasePriceAddressArr()
  // get all factory
  const calls = [
    {
      address: factoryCreatorAddress,
      name: 'getAllFactories',
      params: [],
    },
  ]
  const [
    factoriesData,
  ] = await multicall(factoryCreatorABI, calls)
  const factories = factoriesData[0]

  // get pool info
  const data:Farm[][] = await Promise.all(
    factories.map(async (factory) => {
      const calls1 = [
        {
          address: factory,
          name: 'poolIds',
          params: [],
        },
        {
          address: factory,
          name: 'owner',
          params: [],
        },
      ]
      const [
        poolIdsData,
        owner,
      ] = await multicall(factoryABI,calls1)
      const poolIds = poolIdsData[0].map(Number)
      const factoryFarms: Farm[] = await Promise.all(
        poolIds.map(async(poolId)=>{
          const calls2 = [
            {
              address: factory,
              name: 'getPoolRewardInfoDetail',
              params: [poolId],
            },
            {
              address: factory,
              name: 'getPoolStakeDetail',
              params: [poolId],
            },
            {
              address: factory,
              name: 'core',
              params: [],
            },
            {
              address: factory,
              name: 'stakeRange',
              params: [poolId],
            },
          ]
          const [
            poolRewardInfo,
            poolStakeDetail,
            coreAddress,
            stakeRange,
          ] = await multicall(factoryABI,calls2)

          const calls3 = [
            {
              address: coreAddress[0],
              name: 'getPoolViewInfo',
              params: [poolId],
            },
            {
              address: coreAddress[0],
              name: 'getPoolStakeInfo',
              params: [poolId],
            },
          ]
          const [
            poolViewInfo,
            getPoolStakeInfo,
          ] = await multicall(factoryCoreABI,calls3)

          const poolTypeV =  new BigNumber(poolStakeDetail.poolType.toString()).toNumber()
          // get stake token symbol
          let stakeTokenSymbolV = ''
          let stakeToken0UsdtDecimalsV = 0
          let stakeTokenDecimalsV = 0
          let stakeUsdtPriceV = 0
          let stakedPairUsdtAddress = ''
          let rewardPairUsdtAddress = ''
          let stakeLpTotalInQuoteToken = 0
          let rewardUsdtPriceV = 0
          if(poolTypeV===0){
            // is token pool

            // get stake token price
            const calls41 = [
              {
                address: poolViewInfo[0].stakedPair,
                name: 'getReserves',
                params: [],
              },
              {
                address: poolViewInfo[0].stakedPair,
                name: 'token0',
                params: [],
              },
              {
                address: poolViewInfo[0].stakedPair,
                name: 'token1',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'getReserves',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'token0',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'token1',
                params: [],
              },
            ]

            const [
              getStakeReserves,
              stakedPairToken0,
              stakedPairToken1,
              getRewardReserves,
              rewardPairToken0,
              rewardPairToken1,
            ] = await multicall(lpTokenAbi, calls41)
            const calls40 = [
              {
                address: poolStakeDetail.token,
                name: 'symbol',
                params: [],
              },
              {
                address: poolStakeDetail.token,
                name: 'decimals',
                params: [],
              },
              {
                address: stakedPairToken0[0],
                name: 'decimals',
                params: [],
              },
              {
                address: stakedPairToken1[0],
                name: 'decimals',
                params: [],
              },
              {
                address: rewardPairToken0[0],
                name: 'decimals',
                params: [],
              },
              {
                address: rewardPairToken1[0],
                name: 'decimals',
                params: [],
              },
            ]
            const [
              TokenSymbolV,
              TokenDecimalsV,
              stakedPairToken0Decimals,
              stakedPairToken1Decimals,
              rewardPairToken0Decimals,
              rewardPairToken1Decimals,
            ] = await multicall(erc20, calls40)
            stakeTokenSymbolV = TokenSymbolV[0]
            stakeTokenDecimalsV = TokenDecimalsV[0]

            // tokenA-usdt/bnb/busd     token0 is usdt/bnb/busd
            if(basePriceAddressArr.includes(stakedPairToken0[0].toLowerCase())){
              // r0 is usdt, price = r0/r1
              stakeUsdtPriceV = new BigNumber(getStakeReserves._reserve0.toString())
                .div(new BigNumber(10).pow(stakedPairToken0Decimals[0]))
                .div(new BigNumber(getStakeReserves._reserve1.toString()).div(new BigNumber(10).pow(stakedPairToken1Decimals[0]))).toNumber()
              stakedPairUsdtAddress = stakedPairToken0[0].toLowerCase()
            }else{
              // r1 is usdt, price = r1/r0
              stakeUsdtPriceV = new BigNumber(getStakeReserves._reserve1.toString())
                .div(new BigNumber(10).pow(stakedPairToken1Decimals[0]))
                .div(new BigNumber(getStakeReserves._reserve0.toString()).div(new BigNumber(10).pow(stakedPairToken0Decimals[0]))).toNumber()
              stakedPairUsdtAddress = stakedPairToken1[0].toLowerCase()
            }
            // rewardPairToken   token0 is usdt/bnb/busd
            if(basePriceAddressArr.includes(rewardPairToken0[0].toLowerCase())){
              // r0 is usdt, price = r0/r1
              rewardUsdtPriceV = new BigNumber(getRewardReserves._reserve0.toString())
                .div(new BigNumber(10).pow(rewardPairToken0Decimals[0]))
                .div(new BigNumber(getRewardReserves._reserve1.toString()).div(new BigNumber(10).pow(rewardPairToken1Decimals[0]))).toNumber()

              rewardPairUsdtAddress = rewardPairToken0[0].toLowerCase()
            }else{
              // r1 is usdt, price = r1/r0
              rewardUsdtPriceV = new BigNumber(getRewardReserves._reserve1.toString())
                .div(new BigNumber(10).pow(rewardPairToken1Decimals[0]))
                .div(new BigNumber(getRewardReserves._reserve0.toString()).div(new BigNumber(10).pow(rewardPairToken0Decimals[0]))).toNumber()
              rewardPairUsdtAddress = rewardPairToken1[0].toLowerCase()
            }

          }else{
            // lp
            const calls41 = [
              {
                address: poolStakeDetail.token,
                name: 'decimals',
                params: [],
              },
              {
                address: poolStakeDetail.token,
                name: 'token0',
                params: [],
              },
              {
                address: poolStakeDetail.token,
                name: 'token1',
                params: [],
              },
              {
                address: poolViewInfo[0].stakedPair,
                name: 'getReserves',
                params: [],
              },
              {
                address: poolViewInfo[0].stakedPair,
                name: 'token0',
                params: [],
              },
              {
                address: poolViewInfo[0].stakedPair,
                name: 'token1',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'getReserves',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'token0',
                params: [],
              },
              {
                address: poolViewInfo[0].rewardPair,
                name: 'token1',
                params: [],
              },
              // Total supply of LP tokens
              {
                address: poolStakeDetail.token,
                name: 'totalSupply',
              },
            ]
            const [
              stakeTokenDecimals,
              token0,
              token1,
              getStakeReserves,
              stakedPairToken0,
              stakedPairToken1,
              getRewardReserves,
              rewardPairToken0,
              rewardPairToken1,
              lpTotalSupply,
            ] = await multicall(lpTokenAbi, calls41)
            stakeTokenDecimalsV = stakeTokenDecimals[0]
            const stakePairToken0InStakeToken = stakedPairToken0[0].toLowerCase()===token0[0].toLowerCase()||stakedPairToken0[0].toLowerCase()===token1[0].toLowerCase()
            const calls42 = [
              {
                address: token0[0],
                name: 'symbol',
                params: [],
              },
              {
                address: token0[0],
                name: 'decimals',
                params: [],
              },
              {
                address: token1[0],
                name: 'symbol',
                params: [],
              },
              {
                address: token1[0],
                name: 'decimals',
                params: [],
              },
              {
                address: stakePairToken0InStakeToken?stakedPairToken0[0]:stakedPairToken1[0],
                name: 'balanceOf',
                params: [poolViewInfo[0].stakedPair],
              },
              {
                address: stakedPairToken0[0],
                name: 'decimals',
                params: [],
              },
              {
                address: stakedPairToken1[0],
                name: 'decimals',
                params: [],
              },
              {
                address: rewardPairToken0[0],
                name: 'decimals',
                params: [],
              },
              {
                address: rewardPairToken1[0],
                name: 'decimals',
                params: [],
              },
            ]
            const [
              TokenSymbolV1,
              TokenDecimalsV1,
              TokenSymbolV2,
              TokenDecimalsV2,
              quoteTokenBalanceLP,
              stakedPairToken0Decimals,
              stakedPairToken1Decimals,
              rewardPairToken0Decimals,
              rewardPairToken1Decimals,
            ] = await multicall(erc20, calls42)
            stakeTokenSymbolV = `${TokenSymbolV1[0]}-${TokenSymbolV2[0]}`

            // tokenA-usdt/bnb/busd     token0 in stake LP token
            if(stakedPairToken0[0].toLowerCase()===token0[0].toLowerCase()||stakedPairToken0[0].toLowerCase()===token1[0].toLowerCase()){
              stakeUsdtPriceV = new BigNumber(getStakeReserves._reserve1.toString())
                .div(new BigNumber(10).pow(stakedPairToken1Decimals[0]))
                .div(new BigNumber(getStakeReserves._reserve0.toString()).div(new BigNumber(10).pow(stakedPairToken0Decimals[0]))).toNumber()
              // stake token token0 is  in pair
              stakedPairUsdtAddress = stakedPairToken1[0].toLowerCase()
              // stakedPairToken0  is usdt/bnb/busd
              if(basePriceAddressArr.includes(stakedPairToken0[0].toLowerCase())){
                stakeUsdtPriceV = 1 / stakeUsdtPriceV
                stakedPairUsdtAddress = stakedPairToken0[0].toLowerCase()
              }
            }else{
              // tokenA-usdt/bnb/busd     token1 in stake LP token
              stakeUsdtPriceV = new BigNumber(getStakeReserves._reserve0.toString())
                .div(new BigNumber(10).pow(stakedPairToken0Decimals[0]))
                .div(new BigNumber(getStakeReserves._reserve1.toString()).div(new BigNumber(10).pow(stakedPairToken1Decimals[0]))).toNumber()
              stakedPairUsdtAddress = stakedPairToken0[0].toLowerCase()
              // stakedPairToken1  is usdt/bnb/busd
              if(basePriceAddressArr.includes(stakedPairToken1[0].toLowerCase())){
                stakeUsdtPriceV = 1 / stakeUsdtPriceV
                stakedPairUsdtAddress = stakedPairToken1[0].toLowerCase()
              }
            }
            if(rewardPairToken0[0].toLowerCase()===poolRewardInfo.tokens.toLowerCase()){
              rewardUsdtPriceV = new BigNumber(getRewardReserves._reserve1.toString())
                .div(new BigNumber(10).pow(rewardPairToken1Decimals[0]))
                .div(new BigNumber(getRewardReserves._reserve0.toString()).div(new BigNumber(10).pow(rewardPairToken0Decimals[0]))).toNumber()
              rewardPairUsdtAddress = rewardPairToken0[0].toLowerCase()
              // rewardPairToken0  is usdt/bnb/busd
              if(basePriceAddressArr.includes(rewardPairToken0[0].toLowerCase())){
                rewardUsdtPriceV = 1 / rewardUsdtPriceV
                rewardPairUsdtAddress = rewardPairToken1[0].toLowerCase()
              }
            }else{
              rewardUsdtPriceV = new BigNumber(getRewardReserves._reserve0.toString())
                .div(new BigNumber(10).pow(rewardPairToken0Decimals[0]))
                .div(new BigNumber(getRewardReserves._reserve1.toString()).div(new BigNumber(10).pow(rewardPairToken1Decimals[0]))).toNumber()
              rewardPairUsdtAddress = rewardPairToken0[0].toLowerCase()
              // rewardPairToken0  is usdt/bnb/busd
              if(basePriceAddressArr.includes(rewardPairToken1[0].toLowerCase())){
                rewardUsdtPriceV = 1 / rewardUsdtPriceV
                rewardPairUsdtAddress = rewardPairToken0[0].toLowerCase()
              }
            }
            // Ratio in % of LP tokens that are staked in the pool
            const lpTokenRatio = new BigNumber(poolStakeDetail.stakeAmount.toString()).div(new BigNumber(lpTotalSupply))
            stakeLpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
              .div(new BigNumber(10).pow(stakePairToken0InStakeToken?TokenDecimalsV1[0]:TokenDecimalsV2[0]))
              .times(new BigNumber(2))
              .times(lpTokenRatio).toNumber()

            stakeToken0UsdtDecimalsV = stakePairToken0InStakeToken?TokenDecimalsV1[0]:TokenDecimalsV2[0]
          }
          const calls5 = [
            {
              address: poolRewardInfo.tokens,
              name: 'symbol',
              params: [],
            },
            {
              address: poolRewardInfo.tokens,
              name: 'decimals',
              params: [],
            },
            {
              address: getPoolStakeInfo[0].commissionToken.toString(),
              name: 'symbol',
              params: [],
            },
            {
              address: getPoolStakeInfo[0].commissionToken.toString(),
              name: 'decimals',
              params: [],
            },
          ]

          const [
            rewardTokenSymbolV,
            rewardTokenDecimalsV,
            commissionTokenSymbolV,
            commissionTokenDecimalsV,
          ] = await multicall(erc20, calls5)

          const startBlockV = new BigNumber(poolStakeDetail.startBlock.toString()).toNumber()
          const rewardTotalsV = new BigNumber(poolRewardInfo.rewardTotals.toString()).div(new BigNumber(10).pow(rewardTokenDecimalsV[0]))
          const rewardPerBlockV = new BigNumber(poolRewardInfo.rewardPerBlocks.toString()).div(new BigNumber(10).pow(rewardTokenDecimalsV[0]))
          const minusBlock = Math.round(rewardTotalsV.div(rewardPerBlockV).toNumber() )
          const endBlockTime = await getBlockTimestamp(minusBlock+ startBlockV)

          const newFarm:Farm = {
            pid: poolId,
            poolOwner: owner[0],
            coreAddress: coreAddress[0],
            factoryAddress: factory,
            poolName: poolStakeDetail.name,
            stakeTokenSymbol: stakeTokenSymbolV,
            stakeTokenAddress: poolStakeDetail.token,
            stakeToken0UsdtDecimals: stakeToken0UsdtDecimalsV,
            stakeTokenDecimals: stakeTokenDecimalsV,
            poolType: poolTypeV,
            startBlock: startBlockV,
            endBlock: new BigNumber(poolStakeDetail.endTime.toString()).toNumber(),
            endTime: new BigNumber(poolStakeDetail.endTime.toString()).toNumber(),
            lockSeconds: new BigNumber(poolStakeDetail.lockSeconds.toString()).toNumber(),
            feeAddress: poolStakeDetail.feeAddress,
            feeValue: new BigNumber(poolStakeDetail.feeValue.toString()).toNumber(),
            stakeAmount: new BigNumber(poolStakeDetail.stakeAmount.toString()).toNumber(),
            rewardTotals: rewardTotalsV.toNumber(),
            rewardPerBlock: rewardPerBlockV.toNumber(),
            rewardToken: poolRewardInfo.tokens,
            poolViewInfo: {
              priority: new BigNumber(poolViewInfo[0].priority.toString()).toNumber(),
              officialSite: poolViewInfo[0].officialSite,
              twitter: poolViewInfo[0].twitter,
              telegram: poolViewInfo[0].telegram,
              stakedLogo: poolViewInfo[0].stakedLogo,
              rewardLogo: poolViewInfo[0].rewardLogo,
              stakedPair: poolViewInfo[0].stakedPair,
              rewardPair: poolViewInfo[0].rewardPair,
              stakeUsdtPrice: stakeUsdtPriceV,
              rewardUsdtPrice: rewardUsdtPriceV,
              lpTotalInQuoteToken: stakeLpTotalInQuoteToken,
            },
            mayEndTime: endBlockTime,
            rewardTokenSymbol: rewardTokenSymbolV.toString(),
            rewardTokenDecimals: rewardTokenDecimalsV[0],
            maxStakeAmount: new BigNumber(getPoolStakeInfo[0].maxStakeAmount.toString()).div(new BigNumber(10).pow(stakeTokenDecimalsV)).toNumber(),
            userMaxStakeAmount: new BigNumber(getPoolStakeInfo[0].userMaxStakeAmount.toString()).div(new BigNumber(10).pow(stakeTokenDecimalsV)).toNumber(),
            userMinStakeAmount: new BigNumber(getPoolStakeInfo[0].userMinStakeAmount.toString()).div(new BigNumber(10).pow(stakeTokenDecimalsV)).toNumber(),
            isCertification: certificationPools.poolIds[curChainName].includes(poolId),
            isErrorPool: certificationPools.errorPoolIds[curChainName].includes(poolId),
            commissionTokenAddress: getPoolStakeInfo[0].commissionToken.toString(),
            poolIsStart: curBlockNumber<startBlockV ? 0:1,
            poolEditFeeValue: new BigNumber(getPoolStakeInfo[0].editFeeValue.toString()).div(new BigNumber(10).pow(commissionTokenDecimalsV)).toNumber(),
            poolCloseFeeValue: new BigNumber(getPoolStakeInfo[0].closeFeeValue.toString()).div(new BigNumber(10).pow(commissionTokenDecimalsV)).toNumber(),
            commissionTokenSymbol: commissionTokenSymbolV.toString(),
            stakedPairUsdtAddress: stakedPairUsdtAddress.toLowerCase(),
            rewardPairUsdtAddress: rewardPairUsdtAddress.toLowerCase(),
          }
          return newFarm
        })
      )
      return factoryFarms
    })
  )
  // console.log('farm data', data)
  // return data.flat()

  const data2 = data.flat()
  const filterHiddenData = data2.filter((item)=> !certificationPools.hiddenPoolIds[curChainName].includes(item.pid))
  return filterHiddenData
}

export default null
