import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { Flex, useMatchBreakpoints } from 'trustfi-uikit'
import { useTranslation } from 'contexts/Localization'
import useDelayedUnmount from 'hooks/useDelayedUnmount'
import { useFarmUser } from 'state/farms/hooks'

import Apr, { AprProps } from './Apr'
import Farm, { FarmProps } from './Farm'
import Earned, { EarnedProps } from './Earned'
import Details from './Details'
import CellLayout from './CellLayout'
import { DesktopColumnSchema, MobileColumnSchema } from '../types'
import Staked, { StakedProps } from './Staked'
import Actionbtn from './Actionbtn'
import RowChild from './Actions/RowChild'

export interface RowProps {
  apr: AprProps
  farm: FarmProps
  earned: EarnedProps
  staked: StakedProps
  actionbtn: FarmWithStakedValue
  // multiplier: MultiplierProps
  // liquidity: LiquidityProps
  details: FarmWithStakedValue
  // power: PowerProps
}

interface RowPropsWithLoading extends RowProps {
  userDataReady: boolean
}

const cells = {
  apr: Apr,
  farm: Farm,
  earned: Earned,
  staked: Staked,
  actionbtn: Actionbtn,
  details: Details,
}

const StyledRowDiv = styled.div`
  border: 2px solid ${({ theme }) => theme.expandColor.color.active};
  margin-top: 15px;
  border-radius: ${({ theme }) => theme.expandColor.radius.radius1};
  padding: 15px 0;
  box-sizing: border-box;
`
const StyledTrDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`
const StyledTdDiv = styled.div<{divW: string}>`
  box-sizing: border-box;
  padding: 0 15px;
  width: ${({divW})=>divW};
  position: relative;
  &:first-child {
    &:after {
      display: none;
      content: "";
      width: 1px;
      height: 32px;
      background: ${({ theme }) => theme.expandColor.color.white};
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &.bl-n {
    border-left: none;
  }
  &:after {
    content: "";
    width: 1px;
    height: 32px;
    background: ${({ theme }) => theme.expandColor.color.white};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`

// mobile styled
const StyledFlexM = styled(Flex)`
  width: auto;
  box-sizing: border-box;
  margin-top: 5px;
  &.left {
    padding-left: 10px;
  }
  &.right {
    padding-right: 10px;
  }
`
const StyledTrDivColumnM = styled.div`
  width: 100%;
`

const Row: React.FunctionComponent<RowPropsWithLoading> = ({...props}) => {
  const { details, userDataReady } = props
  const hasStakedAmount = !!useFarmUser(details.pid).stakeAmount.toNumber()
  const [actionPanelExpanded, setActionPanelExpanded] = useState(hasStakedAmount)
  const shouldRenderChild = useDelayedUnmount(actionPanelExpanded, 300)
  const { t } = useTranslation()

  const toggleActionPanel = () => {
    setActionPanelExpanded(!actionPanelExpanded)
  }

  useEffect(() => {
    setActionPanelExpanded(hasStakedAmount)
  }, [hasStakedAmount])

  const { isXl, isXs } = useMatchBreakpoints()

  const isMobile = !isXl
  const tableSchema = isMobile ? MobileColumnSchema : DesktopColumnSchema
  const columnNames = tableSchema.map((column) => column.name)

  const handleRenderRow = () => {
    if (!isXs) {
      return (
        <StyledTrDiv >
          {Object.keys(props).map((key) => {
            const columnIndex = columnNames.indexOf(key)
            if (columnIndex === -1) {
              return null
            }

            switch (key) {
              case 'details':
                return (
                  <StyledTdDiv className="bl-n" key={key} onClick={toggleActionPanel} divW={tableSchema[columnIndex].divW}>
                      <CellLayout>
                        <Details {...props} actionPanelToggled={actionPanelExpanded} />
                      </CellLayout>
                  </StyledTdDiv>
                )
              case 'actionbtn':
                return (
                  <StyledTdDiv key={key} divW={tableSchema[columnIndex].divW}>
                      <CellLayout>
                        <Actionbtn {...props} />
                      </CellLayout>
                  </StyledTdDiv>
                )
              case 'apr':
                return (
                  <StyledTdDiv key={key} divW={tableSchema[columnIndex].divW}>
                      <CellLayout label={t('APR')}>
                        <Apr {...props.apr} hideButton={false} />
                      </CellLayout>
                  </StyledTdDiv>
                )
              default:
                return (
                  <StyledTdDiv key={key} divW={tableSchema[columnIndex].divW}>
                      <CellLayout label={t(tableSchema[columnIndex].label)}>
                        {React.createElement(cells[key], { ...props[key], userDataReady })}
                      </CellLayout>
                  </StyledTdDiv>
                )
            }
          })}
        </StyledTrDiv>
      )
    }

    return (
      <StyledTrDivColumnM>
        <StyledTrDiv onClick={toggleActionPanel}>
          <StyledFlexM className="left" flex={2} justifyContent="space-between" alignItems="center">
            <Farm {...props.farm} />
          </StyledFlexM>
          <StyledFlexM className="right" justifyContent="space-between" alignItems="center">
            <CellLayout>
              <Details {...props} actionPanelToggled={actionPanelExpanded} />
            </CellLayout>
          </StyledFlexM>
        </StyledTrDiv>
        <StyledTrDiv onClick={toggleActionPanel}>
          <StyledFlexM className="left" flex={1} justifyContent="space-between" alignItems="center">
            <CellLayout label={t('Earned')} flex={2}>
              <Earned {...props.earned} userDataReady={userDataReady} />
            </CellLayout>
          </StyledFlexM>
          <StyledFlexM className="right" flex={1} justifyContent="space-between" alignItems="center">
            <CellLayout label={t('APR')} flex={2}>
              <Apr {...props.apr} hideButton={false} />
            </CellLayout>
          </StyledFlexM>
        </StyledTrDiv>
      </StyledTrDivColumnM>

    )
  }

  return (
    <StyledRowDiv>
      {handleRenderRow()}
      {shouldRenderChild && (
        <RowChild {...props} expanded={actionPanelExpanded} />
      )}
    </StyledRowDiv>
  )
}

export default Row
