import React from 'react'
import styled from 'styled-components'
import { Button, Heading, Text } from 'trustfi-uikit'
import Page from 'components/layout/Page'
import { useTranslation } from 'contexts/Localization'
import LogoLight from 'assets/logo/logo-200x200.png'
import useTheme from "../hooks/useTheme";

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  padding-top: 50px;
  box-sizing: border-box;
`
const StyledLogo = styled.div`
  width: 120px;
  height: 120px;
  padding: 15px;
  border-radius: 50%;
  background-color: ${({theme})=>theme.colors.navBg};
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

const NotFound = () => {
  const { t } = useTranslation()
  const { isDark } = useTheme()

  return (
    <Page>
      <StyledNotFound>
        <StyledLogo>
          <img src="/images/logo/logo2.png" alt="logo" />
        </StyledLogo>
        <Heading scale="xxl">404</Heading>
        <Text mb="16px">{t('Page not found.')}</Text>
        <Button as="a" href="/" scale="sm">
          {t('Back Home')}
        </Button>
      </StyledNotFound>
    </Page>
  )
}

export default NotFound
