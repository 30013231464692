import React, { useState, useMemo } from 'react'
import { Input } from 'trustfi-uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse'
import { useSnackbar } from 'notistack'
import Resizer from "react-image-file-resizer";

import { PHOTO_MAX_SIZE } from '../../../../config'
import useUploadImg from '../../hooks/useUploadImg'
import { resizeFile } from '../../hooks/helpers'

const StyledFileBtn = styled.div`
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  background-color: ${({theme})=>theme.expandColor.color.pageBg};
  height: 60px;
  width: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  & img{
    width: 100%;
    height:  100%;
    object-fit: contain;
  }
  & svg {
    fill: ${({theme})=>theme.expandColor.color.active};
   }
  &.choose {
    border-radius: 50%;
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  }
`

const Container = styled.div`
  flex: 1;
`
const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

interface Props {
  onChange: (e: string) => void,
  onBlurFun?: ()=>void
  value?: string
  inputId:string
}


const UploadImg: React.FC<Props> = ({ onChange: onChangeCallback,onBlurFun,value,inputId }) => {
  const [ipfsUrl, setIpfsUrl] = useState(value)
  const [inputType,setInputType] = useState('file')
  const classes = useStyles();
  const debouncedOnChange = useMemo(
    () => debounce((e: string) => onChangeCallback(e), 500),
    [onChangeCallback],
  )
  const { enqueueSnackbar } = useSnackbar();
  const {onUploadImg} = useUploadImg()

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    if(!file) return false
    if(file && file.size > PHOTO_MAX_SIZE){
      enqueueSnackbar('Image cannot exceed 1Mb', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 3000,
        TransitionComponent: Collapse,
      });
      return false
    }
    const compress =  await resizeFile(file)
    if(!compress) return false
    const uploadRes = await onUploadImg(compress)
    if(uploadRes){
      setIpfsUrl(`https://${uploadRes}.ipfs.dweb.link`)
      debouncedOnChange(`https://${uploadRes}.ipfs.dweb.link`)
    }
    // clean input file value
    setInputType('text')
    setInputType('file')
    return true
  }

  return (
    <Container>
      <InputWrapper>
        <label htmlFor={inputId}>
          <StyledFileBtn>
            {ipfsUrl===''?(
              <Add style={{ fontSize: 24 }}/>
            ):(
              <img alt="" src={ipfsUrl}/>
            )}
          </StyledFileBtn>
          <input
            accept="image/png,image/jpeg,image/jpg"
            className={classes.input}
            id={inputId}
            multiple
            type={inputType}
            onChange={onChange}
          />
        </label>

      </InputWrapper>
    </Container>
  )
}

export default UploadImg
