import React from 'react'
import styled from 'styled-components'

import { useTranslation } from '../../../../contexts/Localization'
import { UiContainer } from '../../../../components/layout/StyledUi'
import { UiLuminousTitle } from '../../../components/UiLayout'

interface props {
  title: string
}
const FarmHead: React.FC<props> = ({title})=>{
  const { t } = useTranslation()
  return (
    <UiContainer>
      <UiLuminousTitle>{t(title)}</UiLuminousTitle>
    </UiContainer>
  )
}

export default FarmHead
