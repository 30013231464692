import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
import { useMatchBreakpoints } from 'trustfi-uikit'
import SwiperCore, {
  Pagination,Navigation,Autoplay
} from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.css'

import { useTranslation } from 'contexts/Localization'

const sliderData = [
  {id:1,name: 'Annoucement Slider #1', img: '/images/home/banner/b-1.jpg'},
  {id:2,name: 'Annoucement Slider #2', img: '/images/home/banner/b-2.jpg'},
  {id:3,name: 'Annoucement Slider #3', img: '/images/home/banner/b-3.jpg'},
  {id:4,name: 'Annoucement Slider #4', img: '/images/home/banner/b-4.jpg'},
]

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);
const TopBanner: React.FC = () => {
  const { t } = useTranslation()
  const { isXl, isXs } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <StyledContainer className="flex-between">
      <Swiper
        centeredSlides
        slidesPerView={isMobile?1:4}
        loop
        autoplay={{
          "delay": 5000,
          "disableOnInteraction": false,
        }}
        className="mySwiper swiper-no-swiping"
        pagination={{
          "clickable": true,
          "type": "bullets"
        }}
        navigation
        style={{cursor: 'default'}}
        spaceBetween={30}
      >
        {sliderData.map((item)=>{
          return (
            <SwiperSlide key={`slide-${item.id}`}>
              <StyledSliderItem>
                <img alt="" src={item.img}/>
              </StyledSliderItem>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  padding-top: 30px;
`
const StyledSliderItem = styled.div`
  max-width: 500px;
  max-height: 270px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({theme})=>theme.expandColor.color.white};
  background-color:  ${({theme})=>theme.expandColor.color.color2};
  border-radius:  ${({theme})=>theme.expandColor.radius.radius3};
  margin-bottom: 16px;
  overflow: hidden;
  
  & img{
    max-height: 270px;
    min-height: 200px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export default TopBanner
