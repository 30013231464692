import React from "react";
import styled from "styled-components";
import {Image} from "trustfi-uikit";

const IconRow = styled.div<{boxSize:number}>`
    position: relative;
    background: transparent;
    height: ${({boxSize})=>boxSize}px;
    width: ${({boxSize})=>boxSize}px;
    margin-right: 15px;
`
const IconItem = styled.div<{boxSize:number}>`
    width: ${({boxSize})=>boxSize}px;
    height: ${({boxSize})=>boxSize}px;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    border: 3px solid ${({theme})=>theme.expandColor.color.white};
    background: ${({theme})=>theme.expandColor.color.white};
    
`
const SubIconBox = styled.div<{size:number}>`
    width: ${({size})=>size}px;
    height: ${({size})=>size}px;
    box-sizing: border-box;
    background: ${({theme})=>theme.expandColor.color.white};
    position: absolute;
    bottom: -3px;
    right: -3px;
    border-radius: 50%;
    border: 3px solid ${({theme})=>theme.expandColor.color.white};
    overflow: hidden;
    & img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
`
const LpFarmIcon: React.FC<{imgUrl?: string, size?: number, subImg?: string, subSize?:number}> = ({imgUrl,size=40,subImg,subSize=20}) => {
  if(!imgUrl){
    return null
  }
  const boxSize = size + 6
  const subBoxSize = subSize + 4
  return (
    <IconRow boxSize={boxSize}>
      <IconItem boxSize={boxSize}>
        <Image src={imgUrl} alt='' width={size} height={size} />
      </IconItem>
      {subImg && (
        <SubIconBox size={subBoxSize}>
          <img alt="" src={subImg}/>
        </SubIconBox>
      )}
    </IconRow>
  )
}

export default LpFarmIcon
