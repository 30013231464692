import styled from 'styled-components'

export const ActionContainer = styled.div`
  padding: 16px;
  border-radius: 16px;
  flex-basis: 0;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 12px;
    margin-bottom: 0;
    max-height: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 0;
    max-height: 100px;
  }
`

export const ActionContainer2 = styled.div`
  margin-bottom: 16px;
  flex: 2;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 0;
    max-height: 100px;
  }
  &.mr {
    margin-right: 10px;
  }
`

export const ActionTitles = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
`

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`

export const Subtle = styled.span`
  color: ${({ theme }) => theme.expandColor.color.color1};
  font-size: 14px;
  line-height: 1.2;
`

export const ActionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    flex-direction: row;
  }
`
export const Earned = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.2;
`

export const Staked = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textSubtle};
  line-height: 1.2;
`
