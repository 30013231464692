import React from 'react'
import styled from 'styled-components'
import { UiContainer } from 'components/layout/StyledUi'
import { PoolSpaceData } from 'state/types'

interface props {
  detail: PoolSpaceData
}
const TopBanner:React.FC<props> = ({detail})=>{
  return (
    <StyledBox>
      <UiContainer>
        <StyledNameRow>
          <img alt="" src={detail.logoUrl}/>
          <h3>{detail.spaceName}</h3>
        </StyledNameRow>
        <StyledInfoRow>
          <StyledInfoDesc>{detail.desc}</StyledInfoDesc>
          <StyledSocialRow>
            {detail.website && detail.website!=='' && (
              <a href={`${detail.website.slice(0,4) === "http"?'':'https://'}${detail.website}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/website.png" alt="website"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.twitter && detail.twitter!=='' && (
              <a href={`${detail.twitter.slice(0,4) === "http"?'':'https://'}${detail.twitter}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/twitter-icon.png" alt="twitter"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.telegram && detail.telegram!=='' && (
              <a href={`${detail.telegram.slice(0,4) === "http"?'':'https://'}${detail.telegram}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/telegram-icon.png" alt="telegram"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.discord && detail.discord!=='' && (
              <a href={`${detail.discord.slice(0,4) === "http"?'':'https://'}${detail.discord}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/discord-icon.png" alt="discord"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.coinmarketcap && detail.coinmarketcap!=='' && (
              <a href={`${detail.coinmarketcap.slice(0,4) === "http"?'':'https://'}${detail.coinmarketcap}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/coinmarketcap-icon.png" alt="coinmarketcap"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.coingecko && detail.coingecko!=='' && (
              <a href={`${detail.coingecko.slice(0,4) === "http"?'':'https://'}${detail.coingecko}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/coingecko-icon.png" alt="coingecko"/>
                </StyledSocialItem>
              </a>
            )}
            {detail.email && detail.email!=='' && (
              <a href={`mailto:${detail.email}`} target="_blank" rel="noreferrer">
                <StyledSocialItem>
                  <img src="/images/space/social/email-icon.png" alt="email"/>
                </StyledSocialItem>
              </a>
            )}
          </StyledSocialRow>
        </StyledInfoRow>
      </UiContainer>
    </StyledBox>
  )
}

const StyledBox = styled.div`
  margin-top: 30px;
  border-top: 4px solid ${({theme})=>theme.expandColor.color.active};
  border-bottom: 4px solid ${({theme})=>theme.expandColor.color.active};
  padding: 30px 0;
  ${({theme})=>theme.mediaQueries.sm} {
    padding: 60px 0;
  }
`
const StyledNameRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({theme})=>theme.expandColor.color.white};
  & img {
    width: 110px;
    height: 110px;
    object-fit:  contain;
    border-radius: 50%;
    background: ${({theme})=>theme.expandColor.color.white};
  }
  
  & h3 {
    font-size: 35px;
    font-weight: bold;
    flex: 2;
    padding-left: 20px;
  }
`
const StyledInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${({theme})=>theme.mediaQueries.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  & > div {
      margin-top: 20px;
  }
`
const StyledInfoDesc = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: ${({theme})=>theme.expandColor.color.white};
  ${({theme})=>theme.mediaQueries.sm} {
    width: 70%;
    font-size: 26px;
  }
`
const StyledSocialRow = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${({theme})=>theme.mediaQueries.sm} {
    width: 30%;
    justify-content: flex-end;
  }
`
const StyledSocialItem = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  & img {
    width:  40px;
    height: 40px;
    object-fit: center;
  }
`

export default TopBanner
