import BigNumber from 'bignumber.js'
import erc20 from 'vipswap/lib/abi/erc20.json'
import { FarmConfig } from '../../config/constants/types'
import { getAddress, getFactoryCreatorAddress } from '../../utils/addressHelpers'
import multicall from '../../utils/multicall'
import factoryCreatorABI from '../../vipswap/lib/abi/TrustFiStakingFactoryCreator.json'
import { SupportCommToken, UserSupportCommToken } from '../types'

export const fetchSupportCommTokens = async () => {
  const factoryCreatorAddress = getFactoryCreatorAddress()
  const calls = [
    {
      address: factoryCreatorAddress,
      name: 'getSupportCommTokens',
      params: [],
    },
  ]

  const [
    supportCommTokens
  ] = await multicall(factoryCreatorABI, calls)
  const supportCommTokenArrRet = supportCommTokens.supportCommTokenArrRet
  const arr: SupportCommToken[] = await Promise.all(
    supportCommTokenArrRet.map(async(item,i)=>{
      const calls1 = [
        {
          address: item,
          name: 'symbol',
          params: [],
        },
        {
          address: item,
          name: 'decimals',
          params: [],
        },
      ]

      const [
        itemSymbol,
        itemDecimals,
      ] = await multicall(erc20, calls1)

      return {
        supportCommToken: item,
        poolAmounts: new BigNumber(supportCommTokens.poolAmounts[i].toString()).div(new BigNumber(10).pow(itemDecimals)).toNumber(),
        editFeeValue: new BigNumber(supportCommTokens.editFeeValue[i].toString()).div(new BigNumber(10).pow(itemDecimals)).toNumber(),
        closeFeeValue: new BigNumber(supportCommTokens.closeFeeValue[i].toString()).div(new BigNumber(10).pow(itemDecimals)).toNumber(),
        states: supportCommTokens.states[i],
        symbol: itemSymbol[0],
      }
    })
  )
  return arr
}

export const fetchUserSupportCommTokens = async (tokens: SupportCommToken[],account: string)=>{
  const arr: UserSupportCommToken[] = await Promise.all(
    tokens.map(async(item,i)=>{
      const calls1 = [
        {
          address: item.supportCommToken,
          name: 'balanceOf',
          params: [account],
        },
        {
          address: item.supportCommToken,
          name: 'decimals',
          params: [],
        },
      ]

      const [
        itemBalance,
        itemDecimals,
      ] = await multicall(erc20, calls1)
      const dataNew:UserSupportCommToken = {
        ...item,
        label:  item.symbol,
        userBalance: new BigNumber(itemBalance.toString()).div(new BigNumber(10).pow(itemDecimals)).toNumber()
      }
      return dataNew
    })
  )
  return arr
}
