import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

import { PaymentSelectOptionProps } from '../components/CreatePool/PaymentSelect'
import { getBep20Contract } from '../../../utils/contractHelpers'
import { useFarms } from '../../../state/farms/hooks'

const useGetUserCommTokens = ()=>{
  const [commTokens, setCommTokens] = useState<PaymentSelectOptionProps[]>([{
    label: '',
    address: '',
    symbol: 'select',
    poolAmount: '0',
    userBalance: 0
  }])
  const {account} = useWeb3React()
  const {userSupportCommTokens} = useFarms()

  useEffect(() => {
    const fetchBalance = async () => {
      const userSupportCommTokensFilter = userSupportCommTokens.filter((item)=>item.states)

      const cTokens = userSupportCommTokensFilter.map((item)=>{
        return {
          label: item.symbol,
          address: item.supportCommToken,
          symbol: item.symbol,
          poolAmount: item.poolAmounts.toString(),
          userBalance: item.userBalance
        }
      })
      setCommTokens(cTokens)
    }
    fetchBalance()
  }, [ userSupportCommTokens, setCommTokens,account])

  return commTokens

}
const useGetUserCommTokensBak = ()=>{
  const [commTokens, setCommTokens] = useState<PaymentSelectOptionProps[]>([{
    label: '-',
    address: '-',
    symbol: '-',
    poolAmount: '0',
    userBalance: 0
  }])
  const {account} = useWeb3React()
  const {supportCommTokens} = useFarms()
  useEffect(() => {
    const fetchBalance = async () => {
      const cTokens = await Promise.all(
        supportCommTokens.map(async(item)=>{
          if(item.supportCommToken === ''){
            return {
              label: '-',
              address: '-',
              symbol: '-',
              poolAmount: '0',
              userBalance: 0
            }
          }
          try {
            const contract = getBep20Contract(item.supportCommToken)
            const decimals = await contract.decimals()
            const res = await contract.balanceOf(account)
            return {
              label: item.symbol,
              address: item.supportCommToken,
              symbol: item.symbol,
              poolAmount: item.poolAmounts.toString(),
              userBalance: new BigNumber(res.toString()).div(new BigNumber(10).pow(decimals)).toNumber()
            }
          }catch (e) {
            console.error(e)
          }
          return {
            label: '-',
            address: '-',
            symbol: '-',
            poolAmount: '0',
            userBalance: 0
          }
        })
      )
      setCommTokens(cTokens)
    }
    fetchBalance()
  }, [ supportCommTokens, setCommTokens,account])

  return commTokens

}
export default useGetUserCommTokens
